import SignIn from '../pages/SignIn'
import RedfSenhas from '../pages/RedfSenha'

import { createNativeStackNavigator } from '@react-navigation/native-stack'

const AuthStack = createNativeStackNavigator()

export default function AuthRoutes () {
  return (
    <AuthStack.Navigator >
      {/* <AuthStack.Screen name='Company' component={SelectCompany} options={{headerShown:false}}/> */}
      <AuthStack.Screen name='SignIn' component={SignIn} options={{ headerShown: false }}/>
      <AuthStack.Screen name='RedfSenha' component={RedfSenhas} options={{ headerShown: false }}/>
    </AuthStack.Navigator>
  )
}
