import { useState } from 'react'
import { View, Modal, Text, TouchableOpacity, StyleSheet, Image, TextInput, ScrollView } from 'react-native'
import firebase from '../../services/firebaseConnection'
import SelectList from 'react-native-dropdown-select-list'
import theme from '../../assets/themes/themeDefault'
import { EvilIcons, MaterialIcons } from '@expo/vector-icons';
import ClientPrevComponent from '../ClientPrevComponent';
import ModalObs from '../ModalObs';

export default function ModalClient ({ 
  visible, exit, sexo, nome, telefone, local, captador, data, idade,
  contribui,
  segEspecial,
  incapacidades,
  incapacidadesCategoria,
  clinica,
  tempoContribui,
  inss,
  ctps,
  resultado,
  nomeIndicacao,
  telefoneIndicacao,
  localIndicacao,
  statusPost,
  obs,
  idPost,
  supervisor
}) {

  const[status, setStatus] = useState('')
  const[ShowVisible, setShowVisible] = useState(false)

  const data5 = [
    {key:'Em Qualificação', value:'Em Qualificação'},
    {key:'SF', value:'SF'},
    {key:'Retorno', value:'Retorno'},
    {key:'Contato S/ Sucesso', value:'Contato S/ Sucesso'},
    {key:'Vem ao Escritório', value:'Vem ao Escritório'},
    {key:'Não Interessado', value:'Não Interessado'},
    {key:'Contratado', value:'Contratado'},
    {key:'JFAL Pronto', value:'JFAL Pronto'},
    {key:'JFAL Protocolado', value:'JFAL Protocolado'},
  ]

  async function handlleDelete(){
    await firebase.firestore().collection('Prev')
    .doc(idPost)
    .delete()
    .then(()=>{
      alert('Registro excluído com sucesso!')
    })
    .catch((e)=>{
      console.log(e)
    })
  }

  async function handlleUpdateStatus(){
    await firebase.firestore().collection('Prev')
    .doc(idPost)
    .update({
      status: status
    })
    .then(()=>{
      alert('Status atualizado com sucesso!')
    })
    .catch((e)=>{
      alert('erro')
      console.log(e)
    })
  }
  return (
    <View>
      <Modal
      animationType='slide'
      transparent={false}
      visible={visible}
      onRequestClose={() => {

      }}
    >
      <View style={styled.container}>
        <View style={{width:'100%',flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
          <TouchableOpacity style={{marginVertical:32}} onPress={exit}>
            <EvilIcons name="close-o" size={32} color="black" />
          </TouchableOpacity>

          {!supervisor &&
          <TouchableOpacity style={{marginVertical:32}} onPress={handlleDelete}>
            <MaterialIcons name="delete" size={32} color="red" />
          </TouchableOpacity>
          }
        </View>

        <ScrollView style={{width:"100%"}}>
          <ClientPrevComponent
           sexo={sexo} 
           nome={nome}
           telefone={telefone}
           local={local}
           captador={captador}
           data={data}
           statusPost={statusPost}
           />

        <Text style={{textAlign:'center', fontWeight:'500', marginVertical:16}}>Status</Text>
        <SelectList 
        setSelected={setStatus} 
        data={data5} 
        placeholder={statusPost}
        onSelect={handlleUpdateStatus} 
        />

        <Text style={{textAlign:'center', fontWeight:'500', marginVertical:16}}>Observações: {obs}</Text>
        <TouchableOpacity 
          style={{
            width:'100%', 
            height:52, 
            backgroundColor:'#eaeaea', 
            justifyContent:'center', 
            alignItems:'center'
            }} onPress={()=>setShowVisible(!ShowVisible)}>
          <Text>Editar Observações</Text>
        </TouchableOpacity> 

        <Text style={{fontSize:16, fontWeight:'500', textAlign:'center', marginTop:16, color:theme.PRIMARY_COLOR}}>Resultado: {resultado}</Text>

        <View style={styled.contentText}>
         <Text style={styled.text}>Idade: {idade}</Text>
        </View>

        <View style={styled.contentText}>
          <Text style={styled.text}>Contribuição: {contribui}</Text>
        </View>

        <View style={styled.contentText}>
          <Text style={styled.text}>Tempo de Contribuição: {tempoContribui}</Text>
        </View>
        
        <View style={styled.contentText}>
          <Text style={styled.text}>Pagando INSS: {inss}</Text>
        </View>

        <View style={styled.contentText}>
          <Text style={styled.text}>CTPS: {ctps}</Text>
        </View>

        <View style={styled.contentText}>
          <Text style={styled.text}>Seg. Especial: {segEspecial}</Text>
        </View>

        <View style={styled.contentText}>
          <Text style={styled.text}>Incapacidades: {incapacidades}, {incapacidadesCategoria}.</Text>
        </View>

        <View style={styled.array}>
          {clinica && clinica.map((item)=>{
            return(
              <View style={styled.contentTextArray}>
                <Text style={{fontSize:18, color:'#fff' || '#fff'}}>{item}</Text>
              </View>
            )
          })}
        </View>

        <Text style={{fontSize:16, fontWeight:'500', textAlign:'center', marginTop:32, marginBottom:16}}>Indicação</Text>

        <View style={styled.contentText}>
          <Text style={styled.text}>Nome: {nomeIndicacao}</Text>
        </View>

        <View style={styled.contentText}>
          <Text style={styled.text}>Telefone: {telefoneIndicacao}</Text>
        </View>

        <View style={styled.contentText}>
          <Text style={styled.text}>Local: {localIndicacao}</Text>
        </View>
          
        <ModalObs onPress={() => setShowVisible(!ShowVisible)} visible={ShowVisible} idPost={idPost} obs={obs}/>

        <View style={{ paddingVertical: 100 }}></View>
      </ScrollView>
      </View>
    </Modal>
    </View>
  )
}

const styled = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 20,
    justifyContent: 'center',
    alignItems: 'center'
  },
  img: {
    width: '100%',
    resizeMode: 'contain',
    marginVertical: -140
  },
  porcentagem: {
    width: 70,
    height: 70,
    borderRadius: 35,
    backgroundColor: '#32CD32',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    marginVertical: 4
  },
  btn: {
    width: '100%',
    height: 60,
    backgroundColor: '#eaeaea',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 30,
    marginTop:16
  },
  inputForm: {
    width: '100%',
    height: 45,
    backgroundColor: '#eaeaea',
    marginBottom: 8,
    borderRadius: 30,
    padding: 12
  },
  contentText:{
    width:'100%',
    height:50,
    backgroundColor:'#eaeaea',
    paddingHorizontal:10,
    justifyContent:'center',
    alignItems:'center',
    borderRadius:20,
    marginTop:16
  },
  text:{
    fontSize:16,
    fontWeight:'500',
  },
  array:{
    width:'100%',
    backgroundColor:'#eaeaea',
    marginTop:16,
    justifyContent:'center',
    alignItems:'center',
    flexDirection:'row',
    display: 'flex',
    flexWrap:'wrap',
    borderRadius:10,
    padding:10
  },
  contentTextArray:{
    height:50,
    backgroundColor:theme.PRIMARY_COLOR,
    borderRadius:10,
    marginRight:16,
    marginTop:8,
    padding:6,
    justifyContent:'center',
    alignItems:'center',
  }
})
