import { useState,useEffect } from 'react'
import {View, StyleSheet,Text, StatusBar, ScrollView} from 'react-native'

import firebase from '../../services/firebaseConnection'

import CardMembros from '../../components/Membros'

export default function Membros({navigation}){

  const [membros, setMembros] = useState([])

  useEffect(() => {
    async function loadMembros () {
      await firebase.firestore().collection('users')
        .orderBy('dataCreated', 'desc')
        .onSnapshot((doc) => {
          const meusPosts = []

          doc.forEach((item) => {
            meusPosts.push({
              id: item.id,
              Nome: item.data().nome,
              Config: item.data().config,
              Whatsapp: item.data().whatsapp,
              Adm: item.data().adm
            })
          })
          setMembros(meusPosts)
        })
    }
    loadMembros()
  }, [])

  
  return(
    <>
    <StatusBar/>
    <ScrollView style={style.container}>
      {membros.map((membro)=>{
        return(
          <CardMembros
            key={membro.id}
            nome={membro.Nome}
            config={membro.Config}
            adm={membro.Adm}
            id={membro.id}
            whats={membro.Whatsapp}
          />
        )
      })}
    </ScrollView>
    </>
  )
}

const style = StyleSheet.create({
  container:{
    flex:1,
    paddingTop:8,
    paddingHorizontal:10
  },
  

})