import { useState, useContext, useEffect } from 'react'
import { AuthContext } from '../../contexts/auth'
import {View, Text, TextInput, ScrollView, StyleSheet, TouchableOpacity, Image} from 'react-native'
import theme from '../../assets/themes/themeDefault'
import { MaterialIcons } from '@expo/vector-icons'

import logoAdvec from '../../assets/logoAdvec.png'
import logoKontroll from '../../assets/logo.png'


export default function RedfSenhas({navigation}){
  const { forgotPassword, loadinAuth } = useContext(AuthContext)
  const [email, setEmail] =  useState('')

  async function HandlleSubmit(){
    if(email === ''){
      alert('Preencha todos os campos')
      return
    }
    forgotPassword(email.trim())
   }

    

  return(
    <ScrollView>
      <View style={style.container}>
          <Image source={theme.NAME_APP === 'Louvor Advec' ? logoAdvec : logoKontroll} style={style.logo}/>
          <Text style={{fontSize:18, fontWeight:'500', marginTop:16, marginBottom:32}}>{theme.NAME_APP}</Text>
          <View style={style.customInput}>
            <MaterialIcons name="email" size={24} color="black" style={{marginRight:4}}/>
            <TextInput style={style.input} placeholder='Email' onChangeText={setEmail}/>
          </View>

          <TouchableOpacity style={style.btnCadastro} onPress={HandlleSubmit}>
            <Text style={{color:'#fff' || '#fff'}}>{loadinAuth ? 'Carregando...' : 'Redefinir senha'}</Text>
          </TouchableOpacity>
      </View>
    </ScrollView>
  )
}
const style = StyleSheet.create({
  container: {
    width:'100%',
    height:'100%',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    paddingTop:200,
    paddingHorizontal:10
  },
  logo:{
    width:100,
    height:150,
    resizeMode:'contain'
  },
  input:{
    width:'90%',
    height:50,
    borderRadius:10,
    outlineStyle: 'none'
  },
  customInput:{
    width:'100%',
    height:50,
    borderWidth:1,
    borderColor:'#cdcdcd',
    borderRadius:10,
    display:'flex',
    flexDirection:'row',
    justifyContent:'center',
    alignItems:'center',
    padding:10,
    marginTop:8,
    marginRight:8
  }, 
   btnCadastro:{
    width:'100%',
    height:50,
    backgroundColor:theme.PRIMARY_COLOR,
    borderRadius:10,
    marginTop:32,
    display:'flex',
    justifyContent:'center',
    alignItems:'center'
  },
});