import { View, Image, Text, StyleSheet } from 'react-native'

import theme from '../../assets/themes/themeDefault'

import { DrawerActions } from '@react-navigation/native'

import { MaterialIcons } from '@expo/vector-icons'

import logoAdvec from '../../assets/logoAdvec.png'
import logoKontroll from '../../assets/logo.png'

export default function Header ({ navigation }) {
  function drawer () {
    navigation.dispatch(DrawerActions.openDrawer())
  }

  return (
    <View style={style.container }>
      <MaterialIcons name='menu' size={30} onPress={drawer} />
      <Text style={{ fontSize: 16, fontWeight: '500', marginLeft: 40 }}>
        {theme.NAME_APP}
      </Text>

      <View style={style.imagePerfil}>
        <Image source={theme.NAME_APP === 'Louvor Advec' ? logoAdvec : logoKontroll} style={style.logoIcon}/>
      </View>

    </View>
  )
}

const style = StyleSheet.create({
  container: {
    width: '100%',
    height: 70,
    backgroundColor: '#f2f2f2',
    paddingHorizontal: 15,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  icon: {
    flex: 1,
    zIndex: 5,
    width: 30,
    height: 30,
    resizeMode: 'contain',
    backgroundColor: '#33cc77'
  },
  imagePerfil: {
    width: 60,
    height: 30,
    borderRadius: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  logoIcon: {
    width: 60,
    height: 60,
    resizeMode: 'contain'
  },
  logoIconCustom: {
    width: 35,
    height: 35,
    borderRadius: 16,
    resizeMode: 'contain'
  }
})
