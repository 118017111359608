import Home from '../pages/Home'
import Lideres from '../pages/Lideres'
import Membros from '../pages/Membros'
import Indisponibilidade from '../pages/Indisponibilidade'
import AddMembro from '../pages/AddMembro'
import ComunicarIndisponibilidade from '../pages/ComunicarIndisponibilidade'
import FormPrevidenc from '../pages/FormPrevidenc'
import ClientPrev from '../pages/ClientPrev'
import ClientPrevCalendar from '../pages/ClientPrevCalendar'
import ClientPrevList from '../pages/ClientPrevList'
import Supervisor from '../pages/Supervisor'

import 'react-native-reanimated'
import { AntDesign } from '@expo/vector-icons'

import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { createDrawerNavigator } from '@react-navigation/drawer'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'

import theme from '../assets/themes/themeDefault'
import CustomDrawer from '../components/CustomDrawer'

const TopTab = createMaterialTopTabNavigator()
const AppStack = createNativeStackNavigator()
const AppDrawer = createDrawerNavigator()
const AppBottom = createBottomTabNavigator()

function MyTabBottom () {
  return (
    <AppBottom.Navigator screenOptions={
      {
        tabBarStyle: {
          backgroundColor: '#fff',
          shadowColor: 'transparent',
          height: 80,
          borderTopColor: 'transparent'
        },
        tabBarShowLabel: false
      }}>
      <AppBottom.Screen name='Home' component={Home} options={{
        headerShown: false,
        tabBarIcon: ({ size, focused, color }) => (
          <AntDesign name="home" size={size} color={focused ? theme.PRIMARY_COLOR : color} />
        )
      }} />

    </AppBottom.Navigator>
  )
}

function MyDrawer () {
  return (
    <AppDrawer.Navigator drawerContent={props => <CustomDrawer {...props}/>}>

      <AppDrawer.Screen name='Home' component={MyTabBottom}
      options={{
        headerShown: false,
        drawerIcon: ({ size, focused, color }) => (
        <AntDesign name="home" size={size} color={focused ? theme.PRIMARY_COLOR : color} />
        )
      }} />

   <AppDrawer.Screen name='Indisponibilidade' component={ComunicarIndisponibilidade}
      options={{
        headerShown: false,
        drawerIcon: ({ size, focused, color }) => (
        <AntDesign name="deleteuser" size={size} color={focused ? theme.PRIMARY_COLOR : color} />
        )
      }} />

  <AppDrawer.Screen name='Supervisor' component={Supervisor}
        options={{
          headerShown: false,
          drawerIcon: ({ size, focused, color }) => (
          <AntDesign name="barschart" size={size} color={focused ? theme.PRIMARY_COLOR : color} />
          ),
          title: 'Meus Resgistros'
        }} />

    <AppDrawer.Screen name='ADM' component={Lideres}
      options={{
        headerShown: false,
        drawerIcon: ({ size, focused, color }) => (
        <AntDesign name="rocket1" size={size} color={focused ? theme.PRIMARY_COLOR : color} />
        )
      }} />

    </AppDrawer.Navigator>
  )
}

export default function AppRoutes () {
  return (
    <AppStack.Navigator>
      <AppStack.Screen name='Home' component={MyDrawer} options={{ headerShown: false }}/>
      <AppStack.Screen name='Membros' component={Membros}/>
      <AppStack.Screen name='IndisponibilidadeList' component={Indisponibilidade}/>
      <AppStack.Screen name='Indisponibilidade' component={ComunicarIndisponibilidade}/>
      <AppStack.Screen name='FormPrev' component={FormPrevidenc} options={{
        title: 'Previdenciário'
      }}/>

      <AppStack.Screen name='ClientPrev' component={ClientPrev} options={{
        title: 'Previdenciário'
      }}/>

      <AppStack.Screen name='ClientPrevCalendar' component={ClientPrevCalendar} options={{
        title: 'Previdenciário'
      }}/>

      
      <AppStack.Screen name='ClientPrevList' component={ClientPrevList} options={{
        title: 'Previdenciário'
      }}/>

        <AppStack.Screen name='AddMembro' component={AddMembro} options={
        {
          title: 'Adicionar Membro'
        }
      }/>

    </AppStack.Navigator>
  )
}
