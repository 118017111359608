import {View, Text, TouchableOpacity} from 'react-native'
import style from './style'
import { Ionicons } from '@expo/vector-icons'

export default function HeaderModalMusicas({titulo, data, hora, equipe, setShowVisible, showVisible, cor}){
  return(
    <View style={style.container}>
      <TouchableOpacity style={{alignSelf:'flex-start'}} onPress={()=> setShowVisible(!showVisible)}>
        <Ionicons name="arrow-back" size={28} color={'white'  || 'white'}/>
      </TouchableOpacity>

      <View style={style.content}>
        <Text style={style.titulo}>{titulo}</Text>
      </View>
    </View>
  )
}