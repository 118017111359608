import { View, StyleSheet, Text, StatusBar } from 'react-native'
import { useContext, useEffect } from 'react'
import { UserPermission } from '../../contexts/userPermission'

import Header from '../../components/Header'
import CardLider from '../../components/CardLider'
import Aguarde from '../../components/Aguarde'

export default function Lideres ({ navigation }) {
  const { config, adm, loadPostsOk } = useContext(UserPermission)
  useEffect(() => {
    loadPostsOk()
  }, [])

  return (
    <>
    <StatusBar/>
    <Header navigation={navigation}/>
    <View style={{ width: '100%', height: '108%' }}>
      {config === 0
        ? <Aguarde/>
        : <>
        {adm === 0
          ? <Aguarde/>
          : <>
      <Text style={style.titulo}>Adm</Text>

      <View style={style.continerCards}>
        <CardLider Titulo='Membros' Icon='group' navigation={navigation} press='Membros'/>
        <CardLider Titulo='Adicionar Membro' Icon='person-add-alt-1' navigation={navigation} press='AddMembro'/>
      </View>

      <View style={style.continerCards}>
        <CardLider Titulo='Indisponibilidade' Icon='error' navigation={navigation} press='IndisponibilidadeList'/>
      </View>

      <Text style={{textAlign:"center", fontWeight:"500", marginVertical:16}}>Captação</Text>

      <View style={style.continerCards}>
        <CardLider Titulo='Previdenciário' Icon='assessment' navigation={navigation} press='ClientPrev'/>
        <CardLider Titulo='Usucapião' Icon='assessment' navigation={navigation} press='IndisponibilidadeList'/>
      </View>

      </>
        }
      </>
      }
    </View>
    </>
  )
}

const style = StyleSheet.create({
  container: {
    flex: 1
  },
  titulo: {
    alignSelf: 'center',
    fontSize: 18,
    fontWeight: '500',
    marginBottom: 16,
    marginTop: 8
  },
  continerCards: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingRight: 10,
    marginBottom: 8
  }

})
