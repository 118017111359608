import { useState,useEffect } from 'react'
import {View, StyleSheet,Text, StatusBar} from 'react-native'

import firebase from '../../services/firebaseConnection'

import CardIndisponibilidade from '../../components/Indisponibilidade'

export default function Indisponibilidade({navigation}){

  const [posts, setPosts] = useState([])

  useEffect(() => {
    async function loadPosts () {
      await firebase.firestore().collection('indisponibilidades')
        .orderBy('DataCompare', 'desc')
        .limit(8)
        .onSnapshot((doc) => {
          const meusPosts = []

          doc.forEach((item) => {
            meusPosts.push({
              id: item.id,
              Data: item.data().Data,
              Nome: item.data().Nome,
              Motivo: item.data().Motivo,
              Contato: item.data().Contato
            })
          })
          setPosts(meusPosts)
        })
    }
    loadPosts()
  }, [])

  
  return(
    <>
    <StatusBar/>
    <View style={style.container}>
      {posts.map((membro)=>{
        return(
          <CardIndisponibilidade
            key={membro.id}
            nome={membro.Nome}
            id={membro.id}
            data={membro.Data}
            motivo={membro.Motivo}
            contato={membro.Contato}
          />
        )
      })}
    </View>
    </>
  )
}

const style = StyleSheet.create({
  container:{
    flex:1,
    paddingTop:8,
    paddingHorizontal:10
  },
  

})