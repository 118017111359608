import { useEffect, useState } from "react";
import { Platform, ScrollView, Text, TextInput, TouchableOpacity, View, StyleSheet } from "react-native";
import firebase from '../../services/firebaseConnection';
import { MaterialIcons } from '@expo/vector-icons';
import DateTimePicker from '@react-native-community/datetimepicker'

import ClientPrevComponent from "../../components/ClientPrevComponent";
import theme from '../../assets/themes/themeDefault'

export default function ClientPrev({navigation}) {
  const [posts, setPosts] = useState([])

  // CALENDARIO MOBILE
  const [date, setDate] = useState(new Date())
  const [mode, setMode] = useState('date')
  const [show, setShow] = useState(false)

  const [busca, setBusca] = useState('')


  const onChange = (event, selectedDate) => {
    const currentDate = selectedDate || date;
    setShow(Platform.OS === 'ios')
    setDate(currentDate)

    let tempDate = new Date(currentDate);
    const dia = String(tempDate.getDate()).padStart(2, '0');
    const mes = String(tempDate.getMonth() + 1).padStart(2, '0');
    const ano = String(tempDate.getFullYear()).padStart(2, '0');
    let Dataok = dia + '/' + mes + '/' + ano
    setDataDia(Dataok)
  }

  const showMode = (currentMode) => {
    setShow(true);
    setMode(currentMode)
  }

  let data = new Date();
  const dia = String(data.getDate()).padStart(2, '0');
  const mes = String(data.getMonth() + 1).padStart(2, '0');
  const ano = String(data.getFullYear()).padStart(2, '0');
  let Dataok = dia + '/' + mes + '/' + ano

  const [dataDia, setDataDia] = useState(Dataok);
  // CALENDARIO MOBILE

  useEffect(() => {
    loadPost()
  }, [dataDia])

  async function loadPost() {
    await firebase.firestore().collection('Prev')
      .where('DataString', '==', dataDia)
      .orderBy('data', 'desc')
      .onSnapshot((doc) => {
        const meusPosts = []

        doc.forEach((item) => {
          meusPosts.push({
            id: item.id,
            status: item.data().status,
            obs: item.data().obs,
            captador: item.data().captador.toUpperCase(),
            clinica: item.data().clinica,
            contribui: item.data().contribui,
            ctps: item.data().ctps,
            data: item.data().data,
            idade: item.data().idade,
            incapacidades: item.data().incapacidades,
            incapacidadesCategoria: item.data().incapacidadesCategoria,
            inss: item.data().inss,
            local: item.data().local.toUpperCase(),
            nome: item.data().nome.toUpperCase(),
            resultado: item.data().resultado,
            segEspecial: item.data().segEspecial,
            sexo: item.data().sexo,
            telefone: item.data().telefone,
            tempoContribui: item.data().tempoContribui,
            nomeIndicacao: item.data().nomeIndicacao,
            telefoneIndicacao: item.data().telefoneIndicacao,
            localIndicacao: item.data().localIndicacao,
          })
        })
        setPosts(meusPosts)
      })
  }

  const postFilter = posts.filter((post) => {
    return (
      post.captador.startsWith(busca.toUpperCase().trim()) ||
      post.local.startsWith(busca.toUpperCase().trim()) ||
      post.nome.startsWith(busca.toUpperCase().trim()) ||
      post.telefone.startsWith(busca.toUpperCase().trim()) ||
      post.status.startsWith(busca.trim())
    )
  })

  return (
    <ScrollView style={{ padding: 10 }}>
        <Text style={{textAlign:"center", fontSize:16, fontWeight:'500'}}>Modo de visualização</Text>
          <TouchableOpacity style={styled.BtnFilter} onPress={()=>navigation.navigate('ClientPrevCalendar')}>
            <Text>Calendario</Text>
          </TouchableOpacity>

          <TouchableOpacity style={styled.BtnFilter} onPress={()=>navigation.navigate('ClientPrevList')}>
            <Text>Lista</Text>
          </TouchableOpacity>
        <View style={{ paddingVertical: 100 }}></View>
    </ScrollView>
  )
}

const styled = StyleSheet.create({
  BtnFilter:{
    width:'100%',
    height:70,
    backgroundColor:theme.PRIMARY_COLOR,
    justifyContent:'center',
    alignItems:'center',
    padding:5,
    borderRadius:10,
    marginVertical:8
  }
})