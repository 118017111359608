import { StyleSheet } from 'react-native'
import theme from '../../assets/themes/themeDefault'

export default StyleSheet.create({
  container: {
    minWidth: '100%',
    height: 150,
    backgroundColor: theme.PRIMARY_COLOR,
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    // marginTop:16,
    paddingHorizontal: 20
  }
})
