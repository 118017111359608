import { createContext, useEffect, useState } from 'react'
import firebase from '../services/firebaseConnection'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { useNavigation } from '@react-navigation/native'

import * as Updates from 'expo-updates'

export const AuthContext = createContext({

})

export default function AuthProvider ({ children }) {
  const [user, setUser] = useState(null)
  const [loadinAuth, setLoadingAuth] = useState(false)
  const [loading, setLoading] = useState(true)

  const [selectCodigo, setSelectCodigo] = useState()

  const [config, setConfig] = useState(null)

  const navigation = useNavigation()

  // Verifica caso tenha algum usuario logado, ja seta os dados na state user
  useEffect(() => {
    async function loadStorage () {
      const storageUser = await AsyncStorage.getItem('SistemaUser')

      if (storageUser) {
        setUser(JSON.parse(storageUser))
        setLoading(false)
      }

      setLoading(false)
    }

    loadStorage()
  }, [])

  async function signUp (email, password, nome, whatsOk) {
    setLoadingAuth(true)

    await firebase.auth().createUserWithEmailAndPassword(email, password)
      .then(async (value) => {
        const uid = value.user.uid

        await firebase.firestore().collection('users')
          .doc(uid).set({
            nome,
            avatarUrl: null,
            config: 0,
            adm: 0,
            whatsapp: whatsOk,
            email: value.user.email,
            dataCreated: firebase.firestore.FieldValue.serverTimestamp()
          })
          .then(() => {
            const data = {
              uid,
              nome,
              email: value.user.email,
              whatsapp: whatsOk,
              avatarUrl: null,
              adm: 0,
              config: 0
            }
            const dataId = uid

            setLoadingAuth(false)
            alert('Usuário cadastrado com sucesso!')
            navigation.navigate('Home')
          })
      })
      .catch((error) => {
        console.log(error)
        setLoadingAuth(false)
      })
  }

  async function signIn (email, password) {
    setLoadingAuth(true)

    await firebase.auth().signInWithEmailAndPassword(email, password)
      .then(async (value) => {
        const uid = value.user.uid

        const userProfile = await firebase.firestore().collection('users')
          .doc(uid).get()

        const data = {
          uid,
          nome: userProfile.data().nome,
          avatarUrl: userProfile.data().avatarUrl,
          whatsapp: userProfile.data().whatsapp,
          adm: userProfile.data().adm,
          config: userProfile.data().config,
          email: value.user.email
        }
        const dataId = uid

        setUser(data)
        storageUser(data)
        setLoadingAuth(false)
      })
      .catch((error) => {
        console.log(error)
        setLoadingAuth(false)
      })
  }

  async function signUpAdm (email, password, nome, whatsOk) {
    setLoadingAuth(true)

    await firebase.auth().createUserWithEmailAndPassword(email, password)
      .then(async (value) => {
        const uid = value.user.uid

        await firebase.firestore().collection('users')
          .doc(uid).set({
            nome,
            avatarUrl: null,
            config: 1,
            adm: 1,
            email:'applouvoradvec@gmail.com',
            whatsapp: whatsOk,
            dataCreated: firebase.firestore.FieldValue.serverTimestamp()
          })
          .then(() => {
            const data = {
              uid,
              nome,
              email: value.user.email,
              whatsapp: whatsOk,
              avatarUrl: null,
              adm:0,
              config:0
            }
            const dataId = uid
            setLoadingAuth(false)
            alert('Conta adm criada com sucesso!')
          })
      })
      .catch((error) => {
        console.log(error)
        setLoadingAuth(false)
      })
  }

  // Função que add item no localStorage
  async function storageUser (data) {
    await AsyncStorage.setItem('SistemaUser', JSON.stringify(data))
  }

  async function signOut () {
    await firebase.auth().signOut()
    await AsyncStorage.clear()
    setUser(null)
    await Updates.reloadAsync()
  }

  function handlleCode (data) {
    setSelectCodigo(data)
  }

  // REDEFINIÇÃO DE SENHA
  async function forgotPassword (email) {
    setLoadingAuth(true)
    // redefinir Senha
    await firebase.auth().sendPasswordResetEmail(email)
      .then(() => {
        setLoadingAuth(false)
        alert('Verifique sua caixa de e-mail.')
        navigation.navigate('SignIn')
      })
      .catch((error) => {
        setLoadingAuth(false)
        const errorCode = error.code
        const errorMessage = error.message
        console.log(errorCode)
        console.log(errorMessage)
      })
  }

  return (
    <AuthContext.Provider value={{ signed: !!user, user, loading, loadinAuth, signUp, signOut, signIn,signUpAdm, handlleCode, selectCodigo, config, forgotPassword }}>
      {children}
    </AuthContext.Provider>
  )
}
