import { useState } from 'react'
import { View, Modal, Text, TouchableOpacity, StyleSheet, TextInput, ScrollView } from 'react-native'
import firebase from '../../services/firebaseConnection'
import theme from '../../assets/themes/themeDefault'
import { EvilIcons } from '@expo/vector-icons';

export default function ModalObs ({ idPost, visible, obs, onPress }) {
  const[obsInput, setObsInput] = useState(obs)

  async function handlleUpdateObs(){
    await firebase.firestore().collection('Prev')
    .doc(idPost)
    .update({
      obs:obsInput
    })
    .then(()=>{
      alert('Observações atualizadas com sucesso!')
      onPress()
    })
    .catch((e)=>{
      alert('erro')
      console.log(e)
    })
  }
  return (
    <View>
      <Modal
      animationType='slide'
      transparent={true}
      visible={visible}
      onRequestClose={() => {

      }}
    >
      
      <View style={styled.container}>
        <TouchableOpacity style={{marginVertical:32}} onPress={onPress}>
          <EvilIcons name="close-o" size={32} color="white" />
        </TouchableOpacity>

        <View style={styled.content}>
          <Text style={{textAlign:'center', fontWeight:'500', fontSize:16}}>Observações</Text>

          <TextInput multiline={true} placeholder='passo a state da prop status cadastrda no firebase'
            style={{
              width:'100%', 
              height:'95%', 
              backgroundColor:"#cdcdcd",
              marginVertical:8, 
              padding:8,
              borderRadius:10,
            }}

              onChangeText={setObsInput}
              value={obsInput}
            />
        </View>
        <TouchableOpacity style={{width:'70%', height:52, backgroundColor:theme.PRIMARY_COLOR, marginVertical:16, borderRadius:10, justifyContent:'center', alignItems:'center'}} onPress={handlleUpdateObs}>
          <Text style={{color:'#fff'||'#fff'}}>Atualizar</Text>
        </TouchableOpacity>
      </View>
    </Modal>
    </View>
  )
}

const styled = StyleSheet.create({
  container: {
    flex:1,
    paddingHorizontal: 20,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.8)'
  },
  content:{
    width:'100%',
    height:300,
    borderRadius:20,
    backgroundColor:'#eaeaea',
    padding:10,
    paddingBottom:30
  }
})