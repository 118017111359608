import { StyleSheet, View, TextInput, ScrollView, TouchableOpacity, Text, Platform } from 'react-native'
import { useEffect, useState, useContext } from 'react'
import { AuthContext } from '../../contexts/auth'
import { MaterialIcons } from '@expo/vector-icons'
import theme from '../../assets/themes/themeDefault'
import DateTimePicker from '@react-native-community/datetimepicker'
import Header from '../../components/Header'

import firebase from '../../services/firebaseConnection'
import emailjs from '@emailjs/browser'

export default function ComunicarIndisponibilidade ({ navigation }) {
  const { user } = useContext(AuthContext)

  const [conteudo, setConteudo] = useState('')

  // CALENDARIO MOBILE
  const [date, setDate] = useState(new Date())
  const [date2, setDate2] = useState(new Date())
  const [dataValor, setData] = useState()
  const [mode, setMode] = useState('date')
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [emails, setEmails] = useState([])

  useEffect(() => {
    LoadEmails()
  }, [])

  const onChange = (event, selectedDate) => {
    const currentDate = selectedDate || date
    setShow(Platform.OS === 'ios')
    setDate(currentDate)

    const tempDate = new Date(currentDate)
    const dia = String(tempDate.getDate()).padStart(2, '0')
    const mes = String(tempDate.getMonth() + 1).padStart(2, '0')
    const ano = String(tempDate.getFullYear()).padStart(2, '0')

    const semana = ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SÁB']
    const a = semana[tempDate.getDay()]

    const Dataok = dia + '/' + mes + '/' + ano
    setDataDia(Dataok)
    setData(Dataok)
  }

  const showMode = (currentMode) => {
    setShow(true)
    setMode(currentMode)
  }

  const data = new Date()
  const dia = String(data.getDate()).padStart(2, '0')
  const mes = String(data.getMonth() + 1).padStart(2, '0')
  const ano = String(data.getFullYear()).padStart(2, '0')
  const Dataok = dia + '/' + mes + '/' + ano

  const [dataDia, setDataDia] = useState(Dataok)
  // CALENDARIO MOBILE

  // CALENDARIO WEB
  const onChangeWebOK = (event, selectedDate) => {
    const currentDate = selectedDate || date2
    setShow(Platform.OS === 'ios')
    setDate2(currentDate)

    const tempDate = new Date(currentDate)
    const dia = String(tempDate.getDate() + 1).padStart(2, '0')
    const mes = String(tempDate.getMonth() + 1).padStart(2, '0')
    const ano = String(tempDate.getFullYear()).padStart(2, '0')

    const semana = ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SÁB']
    const a = semana[tempDate.getDay() + 1]

    const Dataok = dia + '/' + mes + '/' + ano
    setdateokok(Dataok)
    setData(Dataok)
    setDataDia(Dataok)
  }

  const data2 = new Date()
  const dia2 = String(data2.getDate()).padStart(2, '0')
  const mes2 = String(data2.getMonth() + 1).padStart(2, '0')
  const ano2 = String(data2.getFullYear()).padStart(2, '0')
  const Dataok2 = dia2 + '/' + mes2 + '/' + ano2

  const [dateokok, setdateokok] = useState(Dataok2)
  // CALENDARIO WEB

  async function LoadEmails () {
    await firebase.firestore().collection('users')
      .where('adm', '==', 1)
      .onSnapshot((doc) => {
        const meusPosts = []

        doc.forEach((itens) => {
          meusPosts.push({
            item: itens.data().email
          })
        })
        setEmails(meusPosts)
      })
  }

  console.log(emails)

  async function handlleIndisponibilidade () {
    setLoading(true)
    if (conteudo === '') {
      alert('Diga-nos o motivo da sua indisponibilidade!')
      return
    }
    await firebase.firestore().collection('indisponibilidades')
      .add({
        Nome: user.nome,
        Data: dataDia,
        DataCompare: data,
        Motivo: conteudo,
        Contato: user.whatsapp
      })
      .then(() => {
        sendEmail()
      })
      .catch((e) => {
      // alert(e)
      // setLoading(false)
      })
  }

  function sendEmail () {
    const convertida = emails.map(function (obj) {
      return Object.keys(obj).map(function (chave) {
        return obj[chave]
      })
    })

    const data2 = new Date()
    const dia2 = String(data2.getDate()).padStart(2, '0')
    const mes2 = String(data2.getMonth() + 1).padStart(2, '0')
    const ano2 = String(data2.getFullYear()).padStart(2, '0')
    const Dataok2 = dia2 + '/' + mes2 + '/' + ano2

    const templateParams = {
      from_name: 'App Louvor Advec',
      message: `🔴🔴🔴${'\n'}${'\n'}🔥 Ministério de Louvor Advec ${'\n'}${'\n'}Olá, ${'\n'}Você recebeu uma Indisponibilidade do(a) ${user.nome}.${'\n'}${'\n'}${'\n'}Baixe nosso Aplicativo Louvor Advec: ${'\n'}${'\n'}Android: https://play.google.com/store/apps/details?id=com.kewinabreu.app${'\n'}${'\n'}IOS: https://moonlit-snickerdoodle-2ea301.netlify.app/${'\n'}${'\n'}🔴🔴🔴`,
      email: 'kewinproductions2@gmail.com',
      user_email: convertida
    }

    emailjs.send('service_6pc3y0r', 'template_58kxye2', templateParams, '2TughQ6HrMA-I52jj')
      .then((response) => {
        alert('Enviado com sucesso!')
        navigation.navigate('Home')
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
        console.log(e)
        navigation.navigate('Home')
      })
  }
  //

  return (
    <>
    <Header navigation={navigation}/>
    <View style={{ paddingTop: 20 }}>
      <MaterialIcons name="person-add-disabled" size={90} color="black" style={{ alignSelf: 'center', marginBottom: 24 }}/>

      <ScrollView style={{ paddingHorizontal: 10 }}>

            <View style={{ marginRight: 8 }}>
            {Platform.OS === 'web'
              ? <View style={style.data}>
                    <Text style={{ marginRight: 16 }}>{dataDia}</Text>
                    <input type='date' onChange={(e) => onChangeWebOK(e, e.target.value)} style={style.inpuDataWeb}></input>
                  </View>
              : <View >
                  <TouchableOpacity style={{ alignSelf: 'center', justifyContent: 'center', alignItems: 'center' }} onPress={() => showMode('date')}>
                    <Text style={{ fontSize: 16, fontWeight: '500', marginBottom: 16 }}>Selecione a data da indisponibilidade</Text>
                    <MaterialIcons name="calendar-today" size={24} color={theme.PRIMARY_COLOR} style={{ marginBottom: 8 }}/>
                    <Text>{dataDia}</Text>
                  </TouchableOpacity>
                </View>
              }
            </View>

        <View style={style.customInputObs}>
          <MaterialIcons name="chat" size={24} color="black" style={{ marginRight: 4 }}/>
          <TextInput style={style.inputObs} placeholder='Motivo' multiline={true} onChangeText={setConteudo}/>
       </View>
        <TouchableOpacity style={style.btnCadastro} onPress={handlleIndisponibilidade}>
          <Text>{loading ? 'Carregando, aguarde...' : 'Enviar'}</Text>
        </TouchableOpacity>
      </ScrollView>

      {show && (<DateTimePicker
        testID="dateTimePicker"
        value={date}
        mode={mode}
        is24Hour={true}
        display='default'
        onChange={onChange}
      />)}
    </View>
    </>
  )
}

const style = StyleSheet.create({
  input: {
    width: '90%',
    height: 50,
    borderRadius: 10,
    outlineStyle: 'none'
  },
  customInput: {
    width: '100%',
    height: 50,
    borderWidth: 1,
    borderColor: '#cdcdcd',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    marginTop: 24,
    marginRight: 8
  },
  btnCadastro: {
    width: '100%',
    height: 50,
    backgroundColor: theme.PRIMARY_COLOR,
    borderRadius: 10,
    marginTop: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  inputObs: {
    width: '90%',
    height: '100%',
    borderRadius: 10,
    outlineStyle: 'none'
  },
  customInputObs: {
    width: '100%',
    height: 150,
    // backgroundColor:'#f00'
    borderWidth: 1,
    borderColor: '#cdcdcd',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    marginTop: 24,
    marginRight: 8
  }
})
