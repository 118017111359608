import { View, Text, Image, StyleSheet, TouchableOpacity } from 'react-native'
import theme from '../../assets/themes/themeDefault'
import { useContext } from 'react'
import { AuthContext } from '../../contexts/auth'
import { DrawerContentScrollView, DrawerItemList } from '@react-navigation/drawer'
import { MaterialIcons } from '@expo/vector-icons'

import logoAdvec from '../../assets/logoAdvec.png'
import logoKontroll from '../../assets/logo.png'

export default function CustomDrawer (props) {
  const { signOut } = useContext(AuthContext)

  return (
    <View style={{ flex: 1, paddingBottom: 20 }}>
      <DrawerContentScrollView {...props}>

        <View style={style.container}>
            <Image source={theme.NAME_APP === 'Louvor Advec' ? logoAdvec : logoKontroll} style={style.perfil}/>
            <Text style={style.nameCompany}>{theme.NAME_APP}</Text>
        </View>

        <DrawerItemList {...props}/>
      </DrawerContentScrollView>

      <View style={style.footer}>
        <Text style={{ color: 'gray' }}>Version: {theme.VERSION}</Text>
        <TouchableOpacity onPress={signOut}>
           <MaterialIcons name="logout" size={24} color="black" />
           <Text>Sair</Text>
        </TouchableOpacity>
      </View>
    </View>
  )
}

const style = StyleSheet.create({
  container: {
    width: '100%',
    height: 200,
    backgroundColor: '#eaeaea',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 4,
    borderBottomRightRadius: 50,
    marginBottom: 16
  },
  perfil: {
    width: 150,
    height: 100,
    resizeMode: 'contain'
  },
  nameCompany: {
    marginTop: 8,
    fontSize: 16,
    fontWeight: '500'
  },
  footer: {
    paddingHorizontal: 10,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
})
