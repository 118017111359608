import { useNavigation } from '@react-navigation/native'
import { createContext, useState, useContext, useEffect } from 'react'
import { AuthContext } from './auth'

import firebase from '../services/firebaseConnection'

export const UserPermission = createContext({

})

export default function UserPermissions ({ children }) {
  const { user } = useContext(AuthContext)
  const [adm, setAdm] = useState()
  const [config, setConfig] = useState()
  const [nomeAdm, setNomeAdm] = useState()
  const [Email, setEmail] = useState()

  async function loadPostsOk () {
    await firebase.firestore().collection('users')
      .doc(user.uid)
      .onSnapshot((doc) => {
        if (!doc.exists) {
          console.log('No such document!')
        } else {
          setAdm(doc.data().adm)
          setConfig(doc.data().config)
          setNomeAdm(doc.data().nome)
          setEmail(doc.data().email)
        }
      })
  }

  return (
    <UserPermission.Provider
      value={{
        loadPostsOk,
        adm,
        config,
        nomeAdm,
        user,
        Email
      }}>

      {children}
    </UserPermission.Provider>
  )
}
