import 'react-native-gesture-handler'
import { useEffect } from 'react'
// import { registerRootComponent } from 'expo'
import * as Updates from 'expo-updates'
import theme from './src/assets/themes/themeDefault'

import { NavigationContainer } from '@react-navigation/native'
import AuthContext from './src/contexts/auth'
import UserConfigPermissions from './src/contexts/userPermission'

import Routes from './src/routes'
import { Platform } from 'react-native'

export default function App () {
  useEffect(() => {
    async function updateApp () {
      if (Platform.OS !== 'android') {
        console.log('O update nao passou no Web')
      } else {
        const { isAvailable } = await Updates.checkForUpdateAsync()
        if (isAvailable) {
          alert(`Atualizando... Versão: ${theme.VERSION}`)
          await Updates.fetchUpdateAsync()
          await Updates.reloadAsync()
        }
      }
    }
    updateApp()
  }, [])

  return (
    <NavigationContainer>
      <AuthContext>
        <UserConfigPermissions>
            <Routes/>
        </UserConfigPermissions>
      </AuthContext>
    </NavigationContainer>
  )
}

// registerRootComponent(App)
