import { View, Text, TouchableOpacity } from 'react-native'
import style from './style'
import { AntDesign, MaterialIcons, Ionicons } from '@expo/vector-icons'

export default function Atalhos ({ titulo, icon, pad, press }) {
  return (
    <TouchableOpacity style={{ marginLeft: 8, marginTop: 16 }} onPress={press}>
      <View style={style.container}>
        {icon
          ? <AntDesign name={icon} size={24} color={'white' || 'white'} />
          : pad
            ? <Ionicons name="ios-infinite" size={24} color={'white' || 'white'} />
            : <MaterialIcons name="multitrack-audio" size={24} color={'white' || 'white'}/>
        }

        <Text style={{ marginTop: 2, marginLeft: 8, color: '#fff' || '#fff' }}>{titulo}</Text>
      </View>
    </TouchableOpacity>
  )
}
