import { useEffect, useState } from "react";
import { ScrollView, Text, TextInput, TouchableOpacity, View, StyleSheet } from "react-native";
import firebase from '../../services/firebaseConnection';

import ClientPrevComponent from "../../components/ClientPrevComponent";
import theme from '../../assets/themes/themeDefault'

export default function ClientPrevList() {
  const [posts, setPosts] = useState([])

  const [busca, setBusca] = useState('')
  const [lastKey, setLastKey] = useState()
  const [isEmpty, setEmpty] = useState(false)

  useEffect(() => {
    loadPost()
  }, [])

  async function loadPost() {
    await firebase.firestore().collection('Prev')
      .orderBy('data', 'desc')
      .onSnapshot((doc) => {
        const meusPosts = []

        doc.forEach((item) => {
          meusPosts.push({
            id: item.id,
            status: item.data().status,
            obs: item.data().obs,
            captador: item.data().captador.toUpperCase(),
            clinica: item.data().clinica,
            contribui: item.data().contribui,
            ctps: item.data().ctps,
            data: item.data().data,
            idade: item.data().idade,
            incapacidades: item.data().incapacidades,
            incapacidadesCategoria: item.data().incapacidadesCategoria,
            inss: item.data().inss,
            local: item.data().local.toUpperCase(),
            nome: item.data().nome.toUpperCase(),
            resultado: item.data().resultado,
            segEspecial: item.data().segEspecial,
            sexo: item.data().sexo,
            telefone: item.data().telefone,
            tempoContribui: item.data().tempoContribui,
            nomeIndicacao: item.data().nomeIndicacao,
            telefoneIndicacao: item.data().telefoneIndicacao,
            localIndicacao: item.data().localIndicacao,
          })
        })
        setPosts(meusPosts)
      })
  }

  const postFilter = posts.filter((post) => {
    return (
      post.captador.startsWith(busca.toUpperCase().trim()) ||
      post.local.startsWith(busca.toUpperCase().trim()) ||
      post.nome.startsWith(busca.toUpperCase().trim()) ||
      post.telefone.startsWith(busca.toUpperCase().trim()) ||
      post.status.startsWith(busca.trim()) 
    )
  })

  const dataTags = [
    {tag: 'Em Qualificação'},
    {tag: 'SF'},
    {tag: 'Retorno'},
    {tag: 'Contato S/ Sucesso'},
    {tag: 'Vem ao Escritório'},
    {tag: 'Não Interessado'},
    {tag: 'Contratado'},
    {tag: 'JFAL Pronto'},
    {tag: 'JFAL Protocolado'},
  ]

  return (
    <View>

      <TextInput placeholder="Buscar" onChangeText={setBusca}
      style={{width:'100%',height:50, backgroundColor:'#eaeaea', borderRadius:20, marginBottom:16, padding:10}}
      />

      <Text style={{marginBottom:16, fontWeight:'500'}}>Registros: {postFilter.length}</Text>

      <ScrollView horizontal={true} style={{marginBottom:16, height:40}}>
        <TouchableOpacity TouchableOpacity onPress={()=>setBusca('')} style={styled.BtnFilterAll}>
             <Text style={{color:'#fff'||'#fff'}}>Todos</Text>
        </TouchableOpacity>
        {dataTags.map((item)=>{
          return(
            <TouchableOpacity onPress={()=>setBusca(item.tag)} style={styled.BtnFilter}>
             <Text style={{color:'#fff'||'#fff'}}>{item.tag}</Text>
            </TouchableOpacity>
          )
        })}
      </ScrollView>

      <ScrollView style={{ paddingHorizontal: 8 }}>
        {postFilter.length === 0 || posts.lenght === 0 ?
          <Text style={{ textAlign: 'center', fontWeight: '500', marginVertical: 32 }}>Não existe Registros!</Text>
          :
          <>
            {postFilter.map((item) => {
              return (
                <View style={{ marginTop: 8 }}>
                  <ClientPrevComponent
                    idPost={item.id}
                    statusPost={item.status}
                    sexo={item.sexo}
                    nome={item.nome}
                    telefone={item.telefone}
                    local={item.local}
                    captador={item.captador}
                    data={item.data.toDate().toString()}

                    idade={item.idade}
                    contribui={item.contribui}
                    segEspecial={item.segEspecial}
                    incapacidades={item.incapacidades}
                    incapacidadesCategoria={item.incapacidadesCategoria}
                    clinica={item.clinica}

                    tempoContribui={item.tempoContribui}
                    inss={item.inss}
                    ctps={item.ctps}
                    resultado={item.resultado}

                    nomeIndicacao={item.nomeIndicacao}
                    telefoneIndicacao={item.telefoneIndicacao}
                    localIndicacao={item.localIndicacao}
                    obs={item.obs}

                    press={true}
                  />
                </View>
              )
            })}
        </>
      }

      <View style={{ paddingVertical: 100 }}></View>
    </ScrollView>
    </View>
  )
}

const styled = StyleSheet.create({
  BtnFilter:{
    height:30,
    backgroundColor:theme.PRIMARY_COLOR,
    justifyContent:'center',
    alignItems:'center',
    padding:5,
    borderRadius:10,
    marginRight:8
  },
  BtnFilterAll:{
    height:30,
    backgroundColor:'green',
    justifyContent:'center',
    alignItems:'center',
    padding:5,
    borderRadius:10,
    marginRight:8
  }
})