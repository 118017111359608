import { useState, useContext, useEffect } from 'react'
import { AuthContext } from '../../contexts/auth'
import { conexao } from '../../services/firebaseConnection'
import { View, Text, TextInput, ScrollView, StyleSheet, TouchableOpacity, Image } from 'react-native'
import theme from '../../assets/themes/themeDefault'
import { MaterialIcons } from '@expo/vector-icons'

import logoAdvec from '../../assets/logoAdvec.png'
import logoKontroll from '../../assets/logo.png'

export default function SignIn ({ navigation }) {
  const { signIn, signUpAdm, loadinAuth } = useContext(AuthContext)
  const [email, setEmail] = useState('')
  const [senha, setSenha] = useState('')

  useEffect(() => {
    conexao()
  }, [])

  async function HandlleSubmit () {
    if (email === '' || senha === '') {
      alert('Preencha todos os campos')
      return
    }
    if (senha.length < 6) {
      alert('Sua senha deve conter no mínimo 6 digitos')
      return
    }
    signIn(email.trim(), senha.trim())
  }

  function CriarAdm () {
    const emailAdm = 'adm@kontrollplan.com'
    const senhaAdm = '251225'
    const nomeAdm = 'ADM'
    const whatsAdm = '00'

    signUpAdm(emailAdm, senhaAdm, nomeAdm, whatsAdm)
  }

  return (
      <View style={style.container}>
          <Image source={theme.NAME_APP === 'Louvor Advec' ? logoAdvec : logoKontroll} style={style.logo}/>
          {/* <Text style={{ fontSize: 18, fontWeight: '500', marginTop: 16, marginBottom: 32 }}>{theme.NAME_APP}</Text> */}

          <View style={style.customInput}>
            <MaterialIcons name="email" size={24} color="black" style={{ marginRight: 4 }}/>
            <TextInput style={style.input} placeholder='Email' onChangeText={setEmail}/>
          </View>

          <View style={style.customInput}>
            <MaterialIcons name="security" size={24} color="black" style={{ marginRight: 4 }}/>
            <TextInput style={style.input} placeholder='Senha' onChangeText={setSenha} secureTextEntry={true}/>
          </View>

          <TouchableOpacity style={style.btnCadastro} onPress={HandlleSubmit}>
            <Text style={{ color: '#fff' || '#fff' }}>{loadinAuth ? 'Carregando...' : 'Entrar'}</Text>
          </TouchableOpacity>

          <TouchableOpacity style={{ alignSelf: 'center', marginTop: 8 }} onPress={() => navigation.navigate('RedfSenha')}>
            <Text style={{ color: theme.PRIMARY_COLOR }}>Esqueceu sua senha?</Text>
          </TouchableOpacity>

          {/* <TouchableOpacity style={style.btnCadastro} onPress={CriarAdm}>
            <Text style={{color:'#fff' || '#fff'}}>{loadinAuth ? 'Carregando...' : 'Criar Adm'}</Text>
          </TouchableOpacity> */}

      </View>
  )
}
const style = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    // backgroundColor:'red',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 10
  },
  logo: {
    width: 150,
    height: 150,
    resizeMode: 'contain'
  },
  input: {
    width: '90%',
    height: 50,
    borderRadius: 10,
    outlineStyle: 'none'
  },
  customInput: {
    width: '100%',
    height: 50,
    borderWidth: 1,
    borderColor: '#cdcdcd',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    marginTop: 8,
    marginRight: 8
  },
  btnCadastro: {
    width: '100%',
    height: 50,
    backgroundColor: theme.PRIMARY_COLOR,
    borderRadius: 10,
    marginTop: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
})
