import { useContext } from 'react'
import { View } from 'react-native'
import { AuthContext } from '../contexts/auth'
import AppRoutes from './appRoutes'
import AuthRoutes from './authRoutes'

export default function Routes () {
  const { signed, loading } = useContext(AuthContext)
  // const signed = false
  if (loading) {
    return (
      <View></View>
    )
  }

  if (signed) {
    return (
      <AppRoutes/>
    )
  } else {
    return (
      <AuthRoutes/>
    )
  }
}
