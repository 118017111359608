import {TouchableOpacity,Text, StyleSheet} from 'react-native'
import { MaterialIcons } from '@expo/vector-icons';

export default function CardLider({Titulo, Icon, navigation, press}){

  function ShowPage(){
    navigation.navigate(press)
  }

  return(
    <TouchableOpacity style={style.container} onPress={ShowPage}>
      <MaterialIcons name={Icon} size={24} color="black" style={{marginBottom:8}}/>
      <Text>{Titulo}</Text>
    </TouchableOpacity>
  )
}
const style = StyleSheet.create({
  container:{
    flex:1,
    height:120,
    backgroundColor:'#eaeaea',
    marginLeft:8,
    border:0,
    borderRadius:20,
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
  }
})