export default {
  // KONTROLL PLAN

  NAME_APP: 'Ksa Advogados',
  PRIMARY_COLOR: '#BF9650',
  SECONDARY_COLOR: '#fff',

  // ADVEC

  // NAME_APP:'Louvor Advec',
  // PRIMARY_COLOR: '#38A543',
  // SECONDARY_COLOR: '#cdcdcd',

  // VERSION
  VERSION: '1.0.3'
}
