import { StyleSheet, View, TextInput, ScrollView, TouchableOpacity, Text } from 'react-native'
import { useEffect, useState, useContext } from 'react'
import { AuthContext } from '../../contexts/auth'

import { UserPermission } from '../../contexts/userPermission'

import { MaterialIcons } from '@expo/vector-icons'

export default function AddMembro () {
  const { loadPostsOk } = useContext(UserPermission)

  useEffect(() => {
    loadPostsOk()
  }, [])

  const { signUp, loadinAuth } = useContext(AuthContext)

  const [nome, setNome] = useState('')
  const [whats, setWhats] = useState('')
  const whatsok = `+55${whats}`
  const [email, setEmail] = useState('')
  const [senha, setSenha] = useState('')
  const [confirmSenha, setConfirmSenha] = useState('')

  function handlleCadastro () {
    if (email === '' || senha === '' || confirmSenha === '' || nome === '' || whats === '') {
      alert('Preencha todos os campos.')
      return
    }
    if (nome === 'ADM' || nome === 'adm' || nome === 'Adm') {
      alert('Você não pode usa esse nome!')
      return
    }
    if (senha.length < 6) {
      alert('Sua senha deve conter no mínimo 6 dígitos.')
      return
    }
    if (senha !== confirmSenha) {
      alert('As senhas não correspondem.')
      return
    }
    signUp(email.trim(), senha.trim(), nome, whatsok.trim())
  }

  return (
    <View style={{ paddingTop: 20 }}>
      <MaterialIcons name="person-add-alt-1" size={90} color="black" style={{ alignSelf: 'center' }}/>

      <View style={{ width: '100%', paddingHorizontal: 15, marginTop: 16, alignItems: 'flex-start' }}>
        <Text>Limite de Usuários: </Text>
        <Text>Usuários Cadastrados: </Text>
        <Text>Restantes: </Text>
      </View>

      <ScrollView style={{ paddingHorizontal: 10 }}>
        <View style={style.customInput}>
          <MaterialIcons name="title" size={24} color="black" style={{ marginRight: 4 }}/>
          <TextInput style={style.input} placeholder='Nome Completo *' onChangeText={setNome}/>
        </View>

        <View style={style.customInput}>
          <MaterialIcons name="contact-phone" size={24} color="black" style={{ marginRight: 4 }}/>
          <TextInput style={style.input} placeholder='Whatsapp Ex: 82900000000 *' onChangeText={setWhats}/>
        </View>

        <View style={style.customInput}>
          <MaterialIcons name="email" size={24} color="black" style={{ marginRight: 4 }}/>
          <TextInput style={style.input} placeholder='Email *' onChangeText={setEmail}/>
        </View>

        <View style={style.customInput}>
          <MaterialIcons name="security" size={24} color="black" style={{ marginRight: 4 }}/>
          <TextInput style={style.input} placeholder='Senha *' onChangeText={setSenha}/>
        </View>

        <View style={style.customInput}>
          <MaterialIcons name="security" size={24} color="black" style={{ marginRight: 4 }}/>
          <TextInput style={style.input} placeholder='Confirmar Senha *' onChangeText={setConfirmSenha}/>
        </View>

        <TouchableOpacity style={style.btnCadastro} onPress={handlleCadastro}>
          <Text>{loadinAuth ? 'Carregando...' : 'Cadastrar'}</Text>
        </TouchableOpacity>
      </ScrollView>

    </View>
  )
}

const style = StyleSheet.create({
  input: {
    width: '90%',
    height: 50,
    borderRadius: 10,
    outlineStyle: 'none'
  },
  customInput: {
    width: '100%',
    height: 50,
    borderWidth: 1,
    borderColor: '#cdcdcd',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    marginTop: 12,
    marginRight: 8
  },
  btnCadastro: {
    width: '100%',
    height: 50,
    backgroundColor: '#eaeaea',
    borderRadius: 10,
    marginTop: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
})
