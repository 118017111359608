import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'
import { AntDesign } from '@expo/vector-icons'
import { useEffect, useState } from 'react'
import theme from '../../assets/themes/themeDefault'

export default function Checkbox ({ onPress, nome, opt }) {
  const [selected, setSelected] = useState(false)

  function Toggle () {
    setSelected(!selected)
    onPress()
  }

  return (
    <>
    {opt
      ? <>
    <View style={styled.container}>
        <View style={styled.optionContainer}>
          <TouchableOpacity style={styled.touchableOp} onPress={Toggle}>
            {selected
              ? <AntDesign name="checksquareo" size={24} color="black" />
              : null
            }
          </TouchableOpacity>
          <Text style={{ marginLeft: 8 }}>{nome}</Text>
        </View>
    </View>
    </>
      : <>
        <View style={styled.container}>
          <View style={styled.optionContainer}>
            <TouchableOpacity style={styled.touchable} onPress={Toggle}>
              {selected
                ? <AntDesign name="checksquareo" size={24} color="black" />
                : null
              }
            </TouchableOpacity>
            <Text style={{ marginLeft: 8 }}>{nome}</Text>
          </View>
        </View>
        </>
    }
    </>
  )
}

const styled = StyleSheet.create({
  optionContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    // marginTop: 8
    marginVertical: 8
  },
  touchable: {
    width: 32,
    height: 32,
    borderRadius: 4,
    backgroundColor: theme.PRIMARY_COLOR,
    justifyContent: 'center',
    alignItems: 'center'
  },
  touchableOp: {
    width: 32,
    height: 32,
    borderRadius: 4,
    backgroundColor: '#eaeaea',
    justifyContent: 'center',
    alignItems: 'center'
  },
  optext: {
    marginLeft: 12
  }
})
