import { useEffect, useState, useContext } from 'react'
import { Text, View, StyleSheet, ScrollView, Image, TouchableOpacity, TextInput } from 'react-native'
import firebase from '../../services/firebaseConnection'
import {AuthContext} from '../../contexts/auth'

import Checkbox from '../../components/Checkbox'
import ModalSucess from '../../components/ModalSucess'
import ModalFail from '../../components/ModalFail'

import prev from '../../assets/prev.png'
import themeDefault from '../../assets/themes/themeDefault'

export default function FormPrevidenc ({navigation}) {
  const { user } = useContext(AuthContext)
  const [sexo, setSexo] = useState('')
  const [idade, setIdade] = useState('')
  const [idadeNumero, setIdadeNumero] = useState('')
  const [ctps, setCtps] = useState('')
  const [contribui, setContribui] = useState('')
  const [SegEspecial, setSegEspecial] = useState('')
  const [INSS, setINSS] = useState('')
  const [Incapacidades, setIncapacidades] = useState('')
  const [ArrayClinica, setArrayClinica] = useState([])
  const [capacidadesCategoria, setIncapacidadesCategoria] = useState('')
  const [TempoContribuicao, setTempoContribuicao] = useState('')

  const [Nome, setNome] = useState('')
  const [Telefone, setTelefone] = useState('')
  const [Endereco, setEndereco] = useState('')

  const [showVisible, setShowVisible] = useState(false)
  const [showVisibleFail, setShowVisibleFail] = useState(false)
  const [messageModal, setmessageModal] = useState('')
  const [idPost, setIdPost] = useState('')

  let data = new Date();
  const dia = String(data.getDate()).padStart(2, '0');
  const mes = String(data.getMonth() + 1).padStart(2, '0');
  const ano = String(data.getFullYear()).padStart(2, '0');
  let DataString = dia + '/' + mes + '/' + ano

  useEffect(()=>{
    if(capacidadesCategoria === ''){
      setArrayClinica([])
    }
    if(idade !== 'Idoso'){
      setIdadeNumero('')
    }
  },[capacidadesCategoria, idade])

  function FuncSexo (prop) {
    if (sexo !== '') {
      setSexo('')
    } else {
      setSexo(prop)
    }
  }
  function FuncIdade (prop) {
    if (idade !== '') {
      setIdade('')
    } else {
      setIdade(prop)
    }
  }
  function FuncIdadeNumero (prop) {
    if (idadeNumero !== '') {
      setIdadeNumero('')
    } else {
      setIdadeNumero(prop)
    }
  }
  function FuncCTPS (prop) {
    if (ctps !== '') {
      setCtps('')
    } else {
      setCtps(prop)
    }
  }
  function FuncContribui (prop) {
    if (contribui !== '') {
      setContribui('')
    } else {
      setContribui(prop)
    }
  }
  function FuncSegEspecial (prop) {
    if (SegEspecial !== '') {
      setSegEspecial('')
    } else {
      setSegEspecial(prop)
    }
  }
  function FuncINSS (prop) {
    if (INSS !== '') {
      setINSS('')
    } else {
      setINSS(prop)
    }
  }
  function FuncIncapacidades (prop) {
    if (Incapacidades !== '') {
      setIncapacidades('')
    } else {
      setIncapacidades(prop)
    }
  }
  function FuncIncapacidadesCategoria (prop) {
    if (capacidadesCategoria !== '') {
      setIncapacidadesCategoria('')
    } else {
      setIncapacidadesCategoria(prop)
    }
  }
  function FuncTempoContrubuicao (prop) {
    if (TempoContribuicao !== '') {
      setTempoContribuicao('')
    } else {
      setTempoContribuicao(prop)
    }
  }
  function FuncArrayClinica (prop) {
    if(ArrayClinica.indexOf(prop) === -1){
      setArrayClinica([...ArrayClinica, prop])
    }else{
      let index = ArrayClinica.indexOf(prop);
      ArrayClinica.splice(index, 1);
    }
  }

  function limpaInput(){
    setSexo('')
    setIdade('')
    setIdadeNumero('')
    setCtps('')
    setContribui('')
    setSegEspecial('')
    setINSS('')
    setIncapacidades('')
    setArrayClinica([])
    setIncapacidadesCategoria('')
    setTempoContribuicao('')
  }

  function Result () {
    if (idade === 'Adulto' && Incapacidades === 'Sim' && idadeNumero < 54 && contribui !== 'Sim' && SegEspecial !== 'Não') {
      setmessageModal('Alta Possibilidade de Benefício de Auxilio Doença, Aposentadoria por Invalidez Especial ou Loas 87')
      setShowVisible(!showVisible)
      return
    }
    
    if (idade === 'Adulto' && idadeNumero < 54 && Incapacidades === 'Sim' && contribui !== 'Sim' && SegEspecial !== 'Sim') {
      setmessageModal('Alta Possibilidade Loas 87.')
      setShowVisible(!showVisible)
      return
    }

    if (idade === 'Adulto' && Incapacidades === 'Sim' && idadeNumero < 54 && contribui !== 'Não' && SegEspecial === 'Não' && ctps !== 'Não') {
      setmessageModal('Alta Possibilidade de Benefício de Auxilio Doença ou Aposentadoria por Invalidez')
      setShowVisible(!showVisible)
      return
    }

    if (idade === 'Adulto' && Incapacidades === 'Sim' && idadeNumero < 54 && contribui !== 'Não' && SegEspecial !== 'Não' && ctps !== 'Não') {
      setmessageModal('Alta Possibilidade de Benefício de Auxilio Doença ou Aposentadoria por Invalidez Especial')
      setShowVisible(!showVisible)
      return
    }

   

    if (SegEspecial !== 'Não' && idadeNumero < 54 && TempoContribuicao !== 'Muitos anos' && INSS !== 'Sim' && Incapacidades !=='Não') {
      setmessageModal('Alta Possibilidade de Benefício de Auxílio Doença, Aposentadoria por Invalidez Especial ou Loas 87.')
      setShowVisible(!showVisible)
      return
    }

    if (SegEspecial !== 'Não' && sexo === 'Masculino' && idadeNumero >= 60 && Incapacidades === 'Sim') {
      setmessageModal('Alta Possibilidade de Benefício de Axílio Doença ou Aposentadoria por Invalidez Especial.')
      setShowVisible(!showVisible)
      return
    }

    if (SegEspecial !== 'Não' && sexo === 'Feminino' && idadeNumero >= 55 && Incapacidades === 'Sim') {
      setmessageModal('Alta Possibilidade de Benefício de Auxilio Doença ou Aposentadoria por Invalidez Especial.')
      setShowVisible(!showVisible)
      return
    }

    if (SegEspecial !== 'Não' && Incapacidades === 'Não' && sexo === 'Feminino' && idadeNumero >= 55) {
      setmessageModal('Alta Possibilidade de Aposentadoria por Idade Especial.')
      setShowVisible(!showVisible)
      return
    }

    if (SegEspecial !== 'Não' && Incapacidades === 'Não' && sexo === 'Masculino' && idadeNumero >= 60) {
      setmessageModal('Alta Possibilidade de Aposentadoria por Idade Especial.')
      setShowVisible(!showVisible)
      return
    }

    if (SegEspecial !== 'Não' && Incapacidades === 'Sim' && sexo === 'Feminino' && idadeNumero < 55) {
      setmessageModal('Alta Possibilidade de Benefício de Auxilio Doença ou Aposentadoria por Invalidez Especial.')
      setShowVisible(!showVisible)
      return
    }

    if (SegEspecial !== 'Não' && sexo === 'Masculino' && idadeNumero >= 60 && idadeNumero < 65) {
      setmessageModal('Alta Possibilidade de Aposentadoria por Idade, Especial')
      setShowVisible(!showVisible)
      return
    }
  
    if (idade === 'Adulto' && Incapacidades === 'Sim' && contribui === 'Sim' && TempoContribuicao !== 'Muitos anos' && SegEspecial !== 'Sim') {
      if (ctps !== 'Sim' || INSS !== 'Sim') {
        setmessageModal('Alta Possibilidade Loas 87.')
        setShowVisible(!showVisible)
        return
      }
    }

    if (SegEspecial=== 'Não' && idadeNumero >= 65 && TempoContribuicao !== 'Muitos anos' && INSS === 'Sim' && Incapacidades !=='Não') {
      setmessageModal('Alta Possibilidade de Benefício de Auxílio Doença, Aposentadoria por Invalidez ou Loas Idoso.')
      setShowVisible(!showVisible)
      return
    }

    if (sexo === 'Feminino' && idadeNumero >= 62 && TempoContribuicao === 'Muitos anos') {
      setmessageModal('Alta Possibilidade de Aposentadoria por Idade.')
      setShowVisible(!showVisible)
      return
    }

    if (sexo === 'Masculino' && idadeNumero >= 65 && TempoContribuicao === 'Muitos anos') {
      setmessageModal('Alta Possibilidade de Aposentadoria por Idade.')
      setShowVisible(!showVisible)
      return
    }

    
    if (Incapacidades === 'Sim' && contribui !== 'Sim' && SegEspecial !== 'Não') {
      setmessageModal('Alta Possibilidade Benefício de Auxilio Doença, Aposentadoria Especial ou por Idade.')
      setShowVisible(!showVisible)
      return
    }

    
    if (SegEspecial !== 'Não' && idadeNumero >= 65) {
      setmessageModal('Alta Possibilidade de Aposentadoria por Idade Especial ou Loas 88.')
      setShowVisible(!showVisible)
      return
    }

    if (idadeNumero === 64 && Incapacidades === 'Sim') {
      setmessageModal('Alta Possibilidade Loas 87 e proximo Ano Loas 88.')
      setShowVisible(!showVisible)
      return
    }

    if (idadeNumero >= 65 && TempoContribuicao !== 'Muitos anos') {
      setmessageModal('Alta Possibilidade Loas idoso 88.')
      setShowVisible(!showVisible)
      return
    }

    if (idadeNumero === 64 && TempoContribuicao === 'Muitos anos') {
      setmessageModal('Alta Possibilidade Aposentadoria por Idade.')
      setShowVisible(!showVisible)
      return
    }

    if (idade === 'Adulto' && Incapacidades === 'Sim') {
      if (ctps === 'Sim' || INSS === 'Sim') {
        setmessageModal('Alta Possibilidade de Benefício de Auxilio Doença ou Aposentadoria por Invalidez.')
        setShowVisible(!showVisible)
        return
      }
    }

    if (Incapacidades === 'Sim' && contribui !== 'Sim') {
      setmessageModal('Alta Possibilidade Loas 87.')
      setShowVisible(!showVisible)
      return
    }

    if (idadeNumero === 64) {
      setmessageModal('Alta Possibilidade Loas Idoso L88 no proximo Ano')
      setShowVisible(!showVisible)
      return
    }

    setmessageModal('Não Existe Possibilidades no Momento.')
    setShowVisibleFail(!showVisibleFail)
  }

  async function HandlleSubmit (){
    if(Nome === '' || Telefone === '' || Endereco === ''){
      alert('Preencha todos os dados pessoais.')
      return
    }
    Result()
    await firebase.firestore().collection('Prev')
    .add({
      sexo: sexo,
      idade: idade + ' ' + idadeNumero,
      contribui: contribui,
      inss: INSS,
      ctps: ctps,
      segEspecial: SegEspecial,
      incapacidades: Incapacidades,
      incapacidadesCategoria: capacidadesCategoria,
      tempoContribui: TempoContribuicao,
      clinica: ArrayClinica,
      resultado: messageModal,
      nome: Nome,
      telefone: Telefone,
      local: Endereco,
      data: data,
      DataString: DataString,
      captador:user.nome,
      status: 'Em Qualificação'
    })
    .then(async (value)=>{
      let id = value.id
      setIdPost(id)
    })
    .catch(()=>{
      alert('erro')
    })
  }

  async function updateResultFail(){
    setShowVisibleFail(!showVisibleFail)
    await firebase.firestore().collection('Prev')
    .doc(idPost)
    .update({
      resultado:messageModal
    })
    .then(()=>{
      console.log("Fez o update")
      limpaInput()
      navigation.navigate('Home')
    })
    .catch(()=>{
      console.log("Fez o update")
      limpaInput()
      navigation.navigate('Home')
    })
  }

  async function updateResult(){
    setShowVisible(!showVisible)
    await firebase.firestore().collection('Prev')
    .doc(idPost)
    .update({
      resultado:messageModal
    })
    .then(()=>{
      console.log("Fez o update")
      limpaInput()
      navigation.navigate('Home')
    })
    .catch(()=>{
      console.log("Deu errado")
      limpaInput()
      navigation.navigate('Home')
    })
  }
  
  return (
    <View>
      <ScrollView>
          <Text style={{ textAlign: 'center', marginTop: 24, fontSize: 18, fontWeight: '500' }}>Formulário previdenciário</Text>
        <View style={styled.contentImg}>
          <Image source={prev} style={styled.imgPrev}/>
        </View>

        <Text style={{ textAlign: 'center', marginTop: 24, fontWeight: '500' }}>Sexo: {sexo}</Text>
        <View style={styled.categoria}>
          <Checkbox onPress={() => FuncSexo('Masculino')} nome='Masculino'/>
          <Checkbox onPress={() => FuncSexo('Feminino')} nome='Feminino'/>
        </View>

        <Text style={{ textAlign: 'center', marginTop: 24, fontWeight: '500' }}>idade: {idade} {idadeNumero}</Text>
          <View style={styled.categoria}>
            <Checkbox onPress={() => FuncIdade('Adulto')} nome='0-53'/>
            <Checkbox onPress={() => FuncIdade('Criança')} nome='Criança'/>
            <Checkbox onPress={() => FuncIdade('Adolescente')} nome='Adolescente'/>
            <Checkbox onPress={() => FuncIdade('Idoso')} nome='+ 54'/>
          </View>

          {idade === 'Idoso' &&
            <>
              <Checkbox onPress={() => FuncIdadeNumero(54)} nome='- 55 anos' opt/>
              <Checkbox onPress={() => FuncIdadeNumero(56)} nome='+ 55 anos' opt/>
              <Checkbox onPress={() => FuncIdadeNumero(59)} nome='- 60 anos' opt/>
              <Checkbox onPress={() => FuncIdadeNumero(60)} nome='60 anos' opt/>
              <Checkbox onPress={() => FuncIdadeNumero(61)} nome='61 anos' opt/>
              <Checkbox onPress={() => FuncIdadeNumero(62)} nome='62 anos' opt/>
              <Checkbox onPress={() => FuncIdadeNumero(63)} nome='63 anos' opt/>
              <Checkbox onPress={() => FuncIdadeNumero(64)} nome='64 anos' opt/>
              <Checkbox onPress={() => FuncIdadeNumero(65)} nome='65 anos' opt/>
              <Checkbox onPress={() => FuncIdadeNumero(66)} nome='+ 65 anos' opt/>
            </>
          }

        <Text style={{ textAlign: 'center', marginTop: 24, fontWeight: '500' }}>Contibruição: {contribui}</Text>
        <View style={styled.categoria}>
          <Checkbox onPress={() => FuncContribui('Nunca')} nome='Nunca'/>
          <Checkbox onPress={() => FuncContribui('Não')} nome='Não'/>
          {idade !== 'Criança' && idade !== 'Adolescente' &&
          <Checkbox onPress={() => FuncContribui('Sim')} nome='Sim'/>
          }
        </View>

        {contribui === 'Sim' &&
          <>
        <Text style={{ textAlign: 'center', marginTop: 24, fontWeight: '500' }}>Tempo de Contibruição: {TempoContribuicao}</Text>
          <View style={styled.categoriaSemLinha}>
            <Checkbox onPress={() => FuncTempoContrubuicao('Muitos anos')} nome='Muitos anos' opt/>
            <Checkbox onPress={() => FuncTempoContrubuicao('Poucos anos')} nome='Poucos anos' opt/>
            <Checkbox onPress={() => FuncTempoContrubuicao('Pouquíssimos anos')} nome='Pouquíssimos anos' opt/>
          </View>

        <Text style={{ textAlign: 'center', marginTop: 24, fontWeight: '500' }}>Pagando INSS ?: {INSS}</Text>
          <View style={styled.categoriaSemLinha}>
            <Checkbox onPress={() => FuncINSS('Sim')} nome='Sim' opt/>
            <Checkbox onPress={() => FuncINSS('Não')} nome='Não' opt/>
          </View>

          <Text style={{ textAlign: 'center', marginTop: 24, fontWeight: '500' }}>CTPS: {ctps}</Text>
          <View style={styled.categoriaSemLinha}>
            <Checkbox onPress={() => FuncCTPS('Sim')} nome='Sim' opt/>
            <Checkbox onPress={() => FuncCTPS('Não')} nome='Não' opt/>
          </View>
          </>
        }

        <Text style={{ textAlign: 'center', marginTop: 24, fontWeight: '500' }}>Seg. Especial: {SegEspecial}</Text>
        <View style={styled.categoriaSemLinha}>
          <Checkbox onPress={() => FuncSegEspecial('Não')} nome='Não'/>
          {idade !== 'Criança' && idade !== 'Adolescente' &&
          <>
          <Checkbox onPress={() => FuncSegEspecial('Pescador')} nome='Pescador'/>
          <Checkbox onPress={() => FuncSegEspecial('Rural')} nome='Rural'/>
          </>
          }
        </View>

        <View style={styled.categoria}>
        {idade !== 'Criança' && idade !== 'Adolescente' &&
          <>
          <Checkbox onPress={() => FuncSegEspecial('Artesanato')} nome='Artesanato'/>
          <Checkbox onPress={() => FuncSegEspecial('Outro')} nome='Outro'/>
          </>
        }
        </View>

        <Text style={{ textAlign: 'center', marginTop: 24, fontWeight: '500' }}>Incapacidades: {Incapacidades} </Text>
        <View style={styled.categoriaSemLinha}>
          <Checkbox onPress={() => FuncIncapacidades('Não')} nome='Não'/>
          <Checkbox onPress={() => FuncIncapacidades('Sim')} nome='Sim'/>
        </View>

        {Incapacidades === 'Sim' &&
          <>
          <Text style={{ textAlign: 'center', marginTop: 24, fontWeight: '500' }}>Categoria: {capacidadesCategoria} </Text>
          <Checkbox onPress={() => FuncIncapacidadesCategoria('Clinica')} nome='Clinica' opt/>
          <Checkbox onPress={() => FuncIncapacidadesCategoria('Psiquiatria')} nome='Psiquiatria' opt/>
          <Checkbox onPress={() => FuncIncapacidadesCategoria('Não comprovada')} nome='Não comprovada' opt/>
          </>
        }

        {capacidadesCategoria === 'Clinica' &&
          <>
            <Text style={{ textAlign: 'center', marginTop: 24, fontWeight: '500' }}>Selecione: </Text>
            <Checkbox onPress={() => FuncArrayClinica('Coluna')} nome='Coluna' opt/>
            <Checkbox onPress={() => FuncArrayClinica('Ombro')} nome='Ombro' opt/>
            <Checkbox onPress={() => FuncArrayClinica('Punho')} nome='Punho' opt/>
            <Checkbox onPress={() => FuncArrayClinica('Mão')} nome='Mão' opt/>
            <Checkbox onPress={() => FuncArrayClinica('Dedos')} nome='Dedos' opt/>
            <Checkbox onPress={() => FuncArrayClinica('Joelhos')} nome='Joelhos' opt/>
            <Checkbox onPress={() => FuncArrayClinica('Pernas')} nome='Pernas' opt/>
            <Checkbox onPress={() => FuncArrayClinica('Pés')} nome='Pés' opt/>
            <Checkbox onPress={() => FuncArrayClinica('Coração')} nome='Coração' opt/>
            <Checkbox onPress={() => FuncArrayClinica('Visão')} nome='Visão' opt/>
            <Checkbox onPress={() => FuncArrayClinica('Outros')} nome='Outros' opt/>
          </>
        }

        <View style={{ paddingHorizontal: 10 }}>
          <Text style={{ textAlign: 'center', marginVertical: 24, fontWeight: '500' }}>Dados pessoais </Text>

          <TextInput placeholder='Nome' style={styled.inputForm} onChangeText={setNome}/>
          <TextInput placeholder='Telefone' style={styled.inputForm} onChangeText={setTelefone}/>
          <TextInput placeholder='Endereço' style={styled.inputForm} onChangeText={setEndereco}/>

          <TouchableOpacity style={styled.btn} onPress={HandlleSubmit}>
            <Text>Enviar</Text>
          </TouchableOpacity>
        </View>

        <View style={{ paddingVertical: 100 }}></View>
      </ScrollView>

      <ModalSucess visible={showVisible} exit={updateResult} message={messageModal} idPost={idPost}/>
      <ModalFail visible={showVisibleFail} exit={updateResultFail} message={messageModal} idPost={idPost}/>
    </View>
  )
}

const styled = StyleSheet.create({
  contentImg: {
    width: '100%',
    height: 300,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: -30,
    marginBottom: -30
  },
  imgPrev: {
    width: '100%',
    height: '60%',
    resizeMode: 'contain'
  },
  categoria: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginVertical: 22,
    borderBottomWidth: 0.5,
    borderBottomColor: themeDefault.PRIMARY_COLOR
  },
  categoriaSemLinha: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginVertical: 16
  },
  btn: {
    width: '100%',
    height: 52,
    backgroundColor: themeDefault.PRIMARY_COLOR,
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 16
  },
  inputForm: {
    width: '100%',
    height: 60,
    backgroundColor: '#eaeaea',
    marginBottom: 8,
    borderRadius: 30,
    padding: 16
  }
})
