import { useState } from 'react'
import { View, Modal, Text, TouchableOpacity, StyleSheet, Image, TextInput, ScrollView } from 'react-native'
import sucess from '../../assets/sucess.png'
import firebase from '../../services/firebaseConnection'

export default function ModalSucess ({ visible, exit, message, idPost }) {
  const[Nome, setNome]=useState('')
  const[Telefone, setTelefone]=useState('')
  const[Local, setLocal]=useState('')

  async function CadastraIndica(){
    if(Nome === '' && Telefone === ''){
      alert('Preencha nome e telefone!')
      return
    }
    await firebase.firestore().collection('Prev')
    .doc(idPost)
    .update({
      nomeIndicacao:Nome,
      telefoneIndicacao: Telefone,
      localIndicacao: Local
    })
    .then(()=>{
      alert('Indicação Cadastrada com Sucesso!')
    })
    .catch(()=>{
      alert('Falha na Indicação!')
    })
  }

  return (
    <View>
      <Modal
      animationType='slide'
      transparent={false}
      visible={visible}
      onRequestClose={() => {

      }}
    >
      <View style={styled.container}>
        <ScrollView>
          <View>
            <Text style={{ textAlign: 'center', fontSize: 22, fontWeight: '500', marginTop: 32, marginBottom: 4 }}>Parabéns!</Text>

            <View style={styled.porcentagem}>
              <Text style={{ textAlign: 'center', color: '#fff' || '#fff', fontWeight: '500', fontSize: 18 }}>95%</Text>
            </View>

            <Text style={{ textAlign: 'center', marginBottom: 4, marginTop: 16, fontSize: 18 }}>Você tem {message}</Text>
            <Text style={{ textAlign: 'center' }}></Text>
          </View>
          <Image source={sucess} style={styled.img}/>

          <Text style={{ textAlign: 'center', marginBottom: 4, marginTop: 16, fontSize: 18 }}>Indicação</Text>
          <TextInput placeholder='Nome' style={styled.inputForm} onChangeText={setNome}/>
          <TextInput placeholder='Telefone' style={styled.inputForm} onChangeText={setTelefone}/>
          <TextInput placeholder='Endereço' style={styled.inputForm} onChangeText={setLocal}/>

          <TouchableOpacity onPress={CadastraIndica} style={styled.btn}>
            <Text>Cadastrar Indicação</Text>
          </TouchableOpacity>

          <TouchableOpacity onPress={exit} style={styled.btn}>
            <Text>Voltar</Text>
          </TouchableOpacity>

         <View style={{ paddingVertical: 100 }}></View>
      </ScrollView>
      </View>
    </Modal>
    </View>
  )
}

const styled = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 20,
    justifyContent: 'center',
    alignItems: 'center'
  },
  img: {
    width: '100%',
    height:200,
    resizeMode: 'contain',
  },
  porcentagem: {
    width: 70,
    height: 70,
    borderRadius: 35,
    backgroundColor: '#32CD32',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    marginVertical: 4
  },
  btn: {
    width: '100%',
    height: 60,
    backgroundColor: '#eaeaea',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 30,
    marginTop:16
  },
  inputForm: {
    width: '100%',
    height: 45,
    backgroundColor: '#eaeaea',
    marginBottom: 8,
    borderRadius: 30,
    padding: 12
  }
})
