import {Image, View, Text, StyleSheet} from 'react-native'
import aguarde from '../../assets/aguarde.png'

export default function Aguarde({desafios}){
  return(
    <View style={styled.container}>
      <Text style={styled.texto}>{desafios ? 'Você ainda não criou um desafio!' : 'Aguardando Permissões...'}</Text>
      <Image source={aguarde} style={styled.img}/>
    </View>
  )
}

const styled = StyleSheet.create({
  container:{
    width:'100%',
    height:300,
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
    marginTop:160
  },
  img:{
    width:300,
    height:'100%',
    resizeMode:'contain',
    marginTop:8,
    zIndex:1
  },
  texto:{
    fontSize:18,
    textAlign:"center"
  }
})