import { useState } from 'react'
import { View, Text, TouchableOpacity, StyleSheet, Modal, TextInput, ScrollView } from 'react-native'
import firebase from '../../services/firebaseConnection'
import Header from '../../components/HeaderModalMusicas'
import theme from '../../assets/themes/themeDefault'
import { MaterialIcons, FontAwesome } from '@expo/vector-icons'

export default function CardMembros ({ nome, config, adm, id, whats }) {
  const [showVisible, setShowVisible] = useState(false)
  const [Loading, setLoading] = useState(false)
  const [Nome, setNome] = useState(nome)
  const [Zap, setZap] = useState(whats)

  async function handlleAdm () {
    await firebase.firestore().collection('users')
      .doc(id)
      .update({
        adm: 1
      })
  }

  async function handlleNotAdm () {
    await firebase.firestore().collection('users')
      .doc(id)
      .update({
        adm: 0
      })
  }

  async function handdleActive () {
    await firebase.firestore().collection('users')
      .doc(id)
      .update({
        config: 1
      })
  }

  async function handdleNotActive () {
    await firebase.firestore().collection('users')
      .doc(id)
      .update({
        config: 0
      })
  }

  async function updateDados () {
    await firebase.firestore().collection('users')
      .doc(id)
      .update({
        nome: Nome,
        whatsapp: Zap
      }).then(() => {
        alert('Alterações salvas com sucesso!')
      })
  }

  return (
    <>

    <TouchableOpacity style={style.container} onPress={() => setShowVisible(!showVisible)}>
      <View style={
        {
          marginRight: 16,
          width: 50,
          height: 50,
          backgroundColor: 'transparent',
          borderWidth: 1,
          borderColor: config === 1 ? 'green' : 'red',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 25
        }
        }>
        <MaterialIcons name="person-pin" size={45} color='black'/>
      </View>

      <View>
        <Text style={{ color: adm === 1 && '#DAA520', fontWeight: adm === 1 ? '500' : 'normal' }} >{nome === 'ADM' ? 'Suporte Técnico' : nome}</Text>
      </View>
    </TouchableOpacity>

    <View>
    <Modal
      animationType='slide'
      transparent={false}
      visible={showVisible}
      onRequestClose={() => {

      }}
    >
    <Header
    titulo={nome === 'ADM' ? 'Suporte Técnico' : nome}
    setShowVisible={setShowVisible}
    showVisible={showVisible}
    cor='#eaeaea'
    />
      <ScrollView style={{ flex: 1, width: '100%' }}>

            {nome === 'ADM' || nome === 'Kewin abreu'
              ? <View></View>
              : <>
            <View style={style.contentInfos}>
              <MaterialIcons name="person" size={28} color="gray" style={{ marginRight: 16 }}/>
              <Text style={{ color: 'gray' }}>Estado</Text>
            </View>

            <View style={{ paddingHorizontal: 8 }}>
              <Text style={
                {
                  color: 'gray',
                  fontWeight: '500',
                  fontSize: 16,
                  borderBottomWidth: 1,
                  borderBottomColor: config === 1 ? '#66CDAA' : '#B22222',
                  marginBottom: 16
                }
                }>Ativo: {config === 0 ? 'Não' : 'Sim'}</Text>

            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <TouchableOpacity onPress={handdleActive} style={style.btnAdm}>
                <Text style={{ color: '#fff' || '#fff' }}>Ativar</Text>
              </TouchableOpacity>

              <TouchableOpacity onPress={handdleNotActive} style={style.btnNotAdm}>
                <Text style={{ color: '#fff' || '#fff' }}>Desativar</Text>
              </TouchableOpacity>
            </View>
           </View>

            <View style={style.contentInfos}>
              <MaterialIcons name="admin-panel-settings" size={28} color="gray" style={{ marginRight: 16 }}/>
              <Text style={{ color: 'gray' }}>Permissões</Text>
            </View>

            <View style={{ paddingHorizontal: 8 }}>
              <Text style={
                {
                  color: 'gray',
                  fontWeight: '500',
                  fontSize: 16,
                  borderBottomWidth: 1,
                  borderBottomColor: adm === 1 ? '#66CDAA' : '#B22222',
                  marginBottom: 16
                }
                }>Adm: {adm === 0 ? 'Não' : 'Sim'}</Text>

            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <TouchableOpacity onPress={handlleAdm} style={style.btnAdm}>
                <Text style={{ color: '#fff' || '#fff' }}>Promover Adm</Text>
              </TouchableOpacity>

              <TouchableOpacity onPress={handlleNotAdm} style={style.btnNotAdm}>
                <Text style={{ color: '#fff' || '#fff' }}>Remover Adm</Text>
              </TouchableOpacity>
            </View>
           </View>

                <Text style={{ textAlign: 'center', marginVertical: 20 }}>Dados Pessoais</Text>
          <View style={{ paddingHorizontal: 8 }}>
           <View style={style.contentInfosWhats}>
              <FontAwesome name="certificate" size={28} color="gray" style={{ marginRight: 16 }} />
              <TextInput placeholder={nome} style={{ width: '100%', height: 35 }} onChangeText={setNome} value={Nome}/>
            </View>
            {nome !== Nome && <Text style={{ textAlign: 'center', color: '#B22222', marginTop: -20 }}>Salve as alterações.</Text>}

            <View style={style.contentInfosWhats}>
              <FontAwesome name="whatsapp" size={28} color="gray" style={{ marginRight: 16 }} />
              <TextInput placeholder={whats} style={{ width: '100%', height: 35 }} onChangeText={setZap} value={Zap}/>
            </View>

            {whats !== Zap && <Text style={{ textAlign: 'center', color: '#B22222', marginTop: -20 }}>Salve as alterações.</Text>}

            <TouchableOpacity style={style.btn} onPress={updateDados}>
              <Text style={{ color: '#fff' || '#fff' }}>Salvar alterações</Text>
            </TouchableOpacity>
          </View>
           </>}
      </ScrollView>
    </Modal>
  </View>
    </>
  )
}

const style = StyleSheet.create({
  container: {
    width: '100%',
    height: 80,
    paddingHorizontal: 8,
    backgroundColor: '#eaeaea',
    marginBottom: 8,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 20
  },
  contentInfos: {
    paddingHorizontal: 10,
    marginBottom: 8,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 0.5,
    borderBottomColor: '#cdcdcd'
  },
  contentInfosWhats: {
    paddingHorizontal: 10,
    marginBottom: 32,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 0.5,
    borderBottomColor: '#cdcdcd'
  },
  btnAdm: {
    flex: 1,
    height: 40,
    backgroundColor: '#66CDAA',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 8,
    marginBottom: 32
  },
  btnNotAdm: {
    flex: 1,
    height: 40,
    backgroundColor: '#B22222',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 8,
    marginBottom: 32
  },
  btn: {
    width: '100%',
    height: 50,
    backgroundColor: theme.PRIMARY_COLOR,
    borderRadius: 10,
    marginTop: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
})
