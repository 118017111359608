import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/database'
import AsyncStorage from '@react-native-async-storage/async-storage'

const companysConfig = {
  Ksa: {
    apiKey: "AIzaSyDh9te0LAuBGVWY1n6cTtgmiu6T0lvZlh8",
    authDomain: "kontrollplanadvec-e4c8b.firebaseapp.com",
    projectId: "kontrollplanadvec-e4c8b",
    storageBucket: "kontrollplanadvec-e4c8b.appspot.com",
    messagingSenderId: "415305614154",
    appId: "1:415305614154:web:604a336572c382e28137b3"
  }
}

const companyIds = async () => {
  const codigo = await AsyncStorage.getItem('Empresa')
  const config = companysConfig.Ksa

  if (!firebase.apps.length) {
    firebase.initializeApp(config)
    console.log(config)
  } else {
    firebase.app()
  }
}
// companyIds()

export default firebase
export const conexao = companyIds
