import {StyleSheet} from 'react-native'
import theme from '../../assets/themes/themeDefault'

export default StyleSheet.create({
  container:{
    width:'100%',
    height:150,
    backgroundColor:theme.PRIMARY_COLOR,
    display:'flex',
    alignItems:'center',
    justifyContent:'flex-start',
    paddingTop:20,
    paddingHorizontal:20,
    marginBottom:32
  },
  content:{
    width:'100%',
    marginTop:40,
    display:'flex',
    alignItems:'center',
    justifyContent:'center'
  },
  titulo:{
    fontSize:16,
    fontWeight:'500',
    color: '#fff' || '#fff'
  },
  
})