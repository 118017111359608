import { useState } from "react";
import { Text, View, StyleSheet, TouchableOpacity, Image } from "react-native";
import { Ionicons, AntDesign } from '@expo/vector-icons';

import avatarM from '../../assets/avatarM.png'
import avatarF from '../../assets/avatarF.png'
import ModalClient from "../ModalClient";
import theme from '../../assets/themes/themeDefault'

export default function ClientPrevComponent({
  sexo, nome, telefone, local, captador, data, idade,
  contribui,
  segEspecial,
  incapacidades,
  incapacidadesCategoria,
  clinica,
  tempoContribui,
  inss,
  ctps,
  resultado,
  nomeIndicacao,
  telefoneIndicacao,
  localIndicacao,
  idPost,
  statusPost,
  obs,
  press,
  supervisor
}){

  const [showVisible, setShowVisible] = useState(false)

  let data2 = new Date(data);
    const dia2 = String(data2.getDate()).padStart(2, '0');
    const mes2 = String(data2.getMonth() + 1).padStart(2, '0');
    const ano2 = String(data2.getFullYear()).padStart(2, '0');
    let Dataok = dia2 + '/' + mes2 + '/' + ano2


  return(
    <>
    <TouchableOpacity style={styled.container} onPress={()=> press && setShowVisible(!showVisible)}>
      <View style={styled.contentImage}>
        <View style={styled.borderImage}>
          {sexo === 'Feminino' ? 
          <>
          <Image source={avatarF} style={styled.imageSexo}/>
          </>
          :
          <Image source={avatarM} style={styled.imageSexo}/>
          }
        </View>
        <Text style={{marginTop:8}}>{Dataok}</Text>
      </View>

      <View style={styled.contentDados}>

        <View style={styled.contentInfo}>
          <AntDesign name="idcard" size={20} color="black"/>
          <Text style={{marginLeft:8, width:'70%'}}>{statusPost ? statusPost :'Em qualificação'}</Text>
        </View>

        <View style={styled.contentInfo}>
          <Ionicons name="person-outline" size={24} color="black" />
          <Text style={{marginLeft:8, width:'70%'}}>{nome}</Text>
        </View>

        <View style={styled.contentInfo}>
          <AntDesign name="phone" size={24} color="black" />
          <Text style={{marginLeft:8, width:'70%'}}>{telefone}</Text>
        </View>

        <View style={styled.contentInfo}>
          <Ionicons name="location-outline" size={24} color="black" />
          <Text style={{marginLeft:8, width:'70%'}}>{local}</Text>
        </View>
          
          <Text style={{marginLeft:8, color:theme.PRIMARY_COLOR}}>captação: {captador}</Text>
      </View>
    </TouchableOpacity>

    <ModalClient visible={showVisible} exit={()=>setShowVisible(!showVisible)}
        sexo={sexo} nome={nome} telefone={telefone} local={local} captador={captador} data={data}
        idade={idade}
        contribui={contribui}
        segEspecial={segEspecial}
        incapacidades={incapacidades}
        incapacidadesCategoria={incapacidadesCategoria}
        clinica={clinica}
        tempoContribui={tempoContribui}
        inss={inss}
        ctps={ctps}
        resultado={resultado}
        nomeIndicacao={nomeIndicacao}
        telefoneIndicacao={telefoneIndicacao}
        localIndicacao={localIndicacao}
        idPost={idPost}
        statusPost={statusPost}
        obs={obs}
        supervisor={supervisor}
    />
   </>
  )
}

const styled = StyleSheet.create({
  container:{
    width:'100%',
    minHeight: 170,
    backgroundColor:'#eaeaea',
    flexDirection:'row',
    borderRadius:20
  },
  contentImage:{
    width:'30%',
    height:'100%',
    borderRadius:10,
    alignItems:'center',
    paddingTop:15
  },
  borderImage:{
    width:80,
    height:80,
    backgroundColor:'#1A75E0',
    borderRadius:40,
    justifyContent:'center',
    alignItems:'center'
  },
  contentDados:{
    padding:10
  },
  contentInfo:{
    flexDirection:'row',
    alignItems:'center',
    marginBottom:6
  },
  imageSexo:{
    width:78,
    height:78,
    resizeMode:'contain'
  }
})