import { useState } from 'react'
import {View, Text, TouchableOpacity, StyleSheet, Modal} from 'react-native'
import * as WebBrowser from 'expo-web-browser';
import Header from '../../components/HeaderModalMusicas'

import { MaterialIcons } from '@expo/vector-icons';

export default function CardIndisponibilidade ({ nome, motivo, id, data, contato }) {
  const [showVisible, setShowVisible] = useState(false)
  
  async function Justificado(){
    const link = `https://wa.me/${contato}?text=*Liderança Ministério de Louvor Advec*%0A%0A*Referente a Indisponibilidade na Data ${data}*%0A%0A*Motivo*:%0A%0A${motivo}%0A%0A*Resposta:*%0A%0A*Justificado*`
    await WebBrowser.openBrowserAsync(link)
  }

  async function NaoJustificado(){
    const link = `https://wa.me/${contato}?text=*Liderança Ministério de Louvor Advec*%0A%0A*Referente a Indisponibilidade na Data ${data}*%0A%0A*Motivo*:%0A%0A${motivo}%0A%0A*Resposta:*%0A%0A*Não Justificado*, Por Favor Procurar a Coordenação.`
    await WebBrowser.openBrowserAsync(link)
  }

  return (
    <>

    <TouchableOpacity style={style.container} onPress={() => setShowVisible(!showVisible)}>
      <View style={
        {
           marginRight:16, 
           width:50, height:50,
           backgroundColor:'transparent', 
           borderWidth:1,
           borderColor: 'red' ,
           display:'flex',
           justifyContent:'center',
           alignItems:'center',
           borderRadius:25
          }
        }>
        <MaterialIcons name="person-pin" size={45} color='black'/>
      </View>

      <View>
        <Text>{nome}</Text>
        <Text style={{color:'red', fontWeight:'500'}} >Indisponivel {data}</Text>
      </View>
    </TouchableOpacity>



    <View>
    <Modal
      animationType='slide'
      transparent={false}
      visible={showVisible}
      onRequestClose={() => {

      }}
    >
      <View style={{flex:1, width:'100%'}}>
          <Header 
            titulo={nome} 
            setShowVisible={setShowVisible} 
            showVisible={showVisible}
            cor='#eaeaea'
            />
            <View style={style.contentInfos}>
              <MaterialIcons name="calendar-today" size={28} color="red" style={{marginRight:16}}/>
              <Text style={{color:'red'}}>Indisponivel na data: {data}</Text>
            </View>

            <View style={style.contentInfos}>
              <MaterialIcons name="chat" size={28} color="gray" style={{marginRight:16}}/>
              <Text style={{color:'gray'}}>Motivo:</Text>
            </View>

            <View style={{width:'100%', paddingHorizontal:10}}>
              <Text style={{color:'gray'}}>{motivo}</Text>
            </View>


            <View style={{display:'flex', flexDirection:'row'}}>
              <TouchableOpacity style={style.btnAdm} onPress={Justificado}>
                <Text style={{color: '#fff' || '#fff'}}>Justificado</Text>
              </TouchableOpacity>

              <TouchableOpacity  style={style.btnNotAdm} onPress={NaoJustificado}>
                <Text style={{color: '#fff' || '#fff'}}>Não Justificado</Text>
              </TouchableOpacity>
            </View>

      </View>
    </Modal>
  </View>
    </>
  )
}


const style = StyleSheet.create({
  container:{
    width:'100%',
    height:80,
    paddingHorizontal:8,
    marginBottom:8,
    display:'flex',
    flexDirection:'row',
    alignItems:'center',
    borderRadius:20
  },
  contentInfos:{
    paddingHorizontal:10,
    marginBottom:24,
    width:'100%',
    display:'flex',
    flexDirection:'row',
    alignItems:'center',
    borderBottomWidth:0.5,
    borderBottomColor:'#cdcdcd',
  },
  btnAdm:{
    flex:1,
    height:40,
    backgroundColor:'#66CDAA',
    display:'flex',
    flexDirection:'row',
    justifyContent:'center',
    alignItems:'center',
    marginTop:16,
    marginBottom:32
  },
  btnNotAdm:{
    flex:1,
    height:40,
    backgroundColor:'#B22222',
    display:'flex',
    flexDirection:'row',
    justifyContent:'center',
    alignItems:'center',
    marginTop:16,
    marginBottom:32
  }
  
})