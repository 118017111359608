import { StyleSheet } from 'react-native'
import theme from '../../assets/themes/themeDefault'

export default  StyleSheet.create({
  Container:{
    paddingHorizontal: 10,
  },
  BemVindo:{
    fontSize:24,
    color:'#222',
    fontWeight:'300',
    marginTop:24,
    marginLeft:8
  },
  UserName:{
    fontSize:24,
    color:'#222',
    fontWeight:'500',
    marginLeft:8
  },
  Banner:{
    marginTop:16,
    width:'100%',
    height:240,
    backgroundColor:'#cdcdcd',
    borderRadius:20,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    justifyContent: "center"
  },
  ImageBanner:{
    width:'100%',
    height:'100%',
    resizeMode:'cover',
    borderRadius:20
  },
  ContainertitleFunction:{
    width:'99%',
    marginTop:32,
    marginBottom:8,
    display:'flex',
    flexDirection:'row',
    alignItems:'center',
    justifyContent:'space-between'
  },
  titleFunction:{
    fontSize:14,
    fontWeight:'500',
  },
  titleFunctionHoje:{
    fontSize:14,
    alignSelf:'center',
    marginTop:16,
    marginBottom:8
  },
  btnVerDesafios:{
    width:'50%',
    height:32,
    backgroundColor:theme.PRIMARY_COLOR,
    borderRadius:20,
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    flexDirection:'row',
    marginTop:4
  }
})