import {
  View,
  Text,
  StatusBar,
  ScrollView,
  TouchableOpacity,
  Platform
} from 'react-native'
import { useContext, useEffect, useState } from 'react'
import * as WebBrowser from 'expo-web-browser'

import style from './style'
import { AuthContext } from '../../contexts/auth'
import { UserPermission } from '../../contexts/userPermission'

import firebase, { conexao } from '../../services/firebaseConnection'

import Header from '../../components/Header'
import Atalhos from '../../components/Atalhos'
import Aguarde from '../../components/Aguarde'

import {
  MaterialIcons
} from '@expo/vector-icons'

export default function Home ({ navigation }) {
  const { user } = useContext(AuthContext)

  const { config, adm, loadPostsOk, Email } = useContext(UserPermission)

  const [escalas, setEscalas] = useState([])
  const [avisos, setAvisos] = useState([])
  const [ranking, setRanking] = useState([])
  // Data atual formatada
  const data2 = new Date()
  const dia2 = String(data2.getDate()).padStart(2, '0')
  const mes2 = String(data2.getMonth() + 1).padStart(2, '0')
  const ano2 = String(data2.getFullYear()).padStart(2, '0')
  const Dataok2 = dia2 + '/' + mes2 + '/' + ano2

  useEffect(() => {
    conexao()
    setTimeout(() => {
      loadPostsOk()
    }, 1500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      loadEscalas()
      loadAvisos()
      loadRanking()
    }, 1500)
  }, [])

  async function loadRanking () {
    await firebase
      .firestore()
      .collection('ranking')
      .orderBy('pontos', 'desc')
      .limit(3)
      .onSnapshot((doc) => {
        const meusPosts = []

        doc.forEach((item) => {
          meusPosts.push({
            id: item.id,
            nome: item.data().nome,
            pontos: item.data().pontos
          })
        })
        setRanking(meusPosts)
      })
  }

  async function loadEscalas () {
    await firebase
      .firestore()
      .collection('escala')
      .where('Data', '==', Dataok2)
      .onSnapshot((doc) => {
        const meusPosts = []

        doc.forEach((item) => {
          meusPosts.push({
            id: item.id,
            Titulo: item.data().Titulo,
            Data: item.data().Data,
            DataCompare: item.data().DataCompare,
            DiaSemana: item.data().DiaSemana,
            Hora: item.data().Hora,
            Obs: item.data().Obs,
            Equipe: item.data().Equipe,
            Participantes: item.data().Participantes,
            M1: item.data().M1,
            M2: item.data().M2,
            M3: item.data().M3,
            M4: item.data().M4,
            M5: item.data().M5
          })
        })
        setEscalas(meusPosts)
      })
  }

  async function loadAvisos () {
    await firebase
      .firestore()
      .collection('avisos')
      .orderBy('DataCreate', 'desc')
      .limit(3)
      .onSnapshot((doc) => {
        const meusPosts = []

        doc.forEach((item) => {
          meusPosts.push({
            id: item.id,
            Nome: item.data().Nome,
            Mensagem: item.data().Mensagem,
            ImageUrl: item.data().ImageUrl
          })
        })
        setAvisos(meusPosts)
      })
  }

  async function Suporte () {
    const link = `https://wa.me/+5582988296021?text=*App Ksa*%0A%0AOlá,%0AEu sou o *${user.nome}* e estou precisando de ajuda!`
    await WebBrowser.openBrowserAsync(link)
  }

  async function ShowLink () {
    const urlLink = 'https://fantastic-puppy-1b62e3.netlify.app/testando'
    await WebBrowser.openBrowserAsync(urlLink)
  }

  return (
    <View style={{ width: '100%', height: '119%' }}>
      <StatusBar />
      <Header navigation={navigation} />
      {config === 0
        ? (
        <Aguarde />
          )
        : (
        <>
          <View style={style.Container}>
            <ScrollView
              style={{ height: Platform.OS === 'web' ? '100vh' : '100%' }}
            >
              <Text style={style.BemVindo}>Olá,</Text>
              <Text style={style.UserName}>{user.nome}</Text>
              <TouchableOpacity
                style={{
                  width: '100%',
                  height: 55,
                  backgroundColor: '#c3c3c3',
                  borderRadius: 10,
                  marginTop: 10,
                  marginBottom: 16,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  paddingHorizontal: 20
                }}
                onPress={Suporte}
              >
                <Text style={{ color: '#fff' || '#fff', fontWeight: '700' }}>
                  Precisa de ajuda?
                </Text>
                <MaterialIcons
                  name="connect-without-contact"
                  size={30}
                  color={'white' || 'white'}
                />
              </TouchableOpacity>

              <ScrollView horizontal={false}>
                <Atalhos
                  titulo="Previdenciário"
                  icon="form"
                  press={() => navigation.navigate('FormPrev')}
                />
                <Atalhos
                  titulo="Usucapião"
                  icon="form"
                  press={() =>
                    navigation.navigate('ComunicarIndisponibilidade')
                  }
                />
              </ScrollView>

              <View
                style={{
                  paddingBottom: Platform.OS === 'web' ? '50vh' : '60%'
                }}
              ></View>
            </ScrollView>
          </View>
        </>
          )}
    </View>
  )
}
